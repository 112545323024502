// Angular
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  ViewChild,
} from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
// RxJS
import { BehaviorSubject, Observable, of, Subscription } from "rxjs";
import { debounceTime, map, startWith, switchMap } from "rxjs/operators";
// CRUD
import { Router } from "@angular/router";
import moment from "moment";
import { NgxPermissionsService } from "ngx-permissions";
import { TypesUtilsService } from "../../../core/_base/crud";
import { EntityLeasingTypes } from "../enums/entity/entityLeasingTypes";
import { EntityType } from "../enums/entity/entityType";
import { EntityTypes } from "../enums/entity/entityTypes";
import { LicensePlatesTypes } from "../enums/vehicle/licensePlatesTypes";
import { SharedService } from "../shared.service";
import { LeasingCalculatorModel } from "./leasing-calculator-model";
import { PermissionsService } from "../../pages/permissions";
import { ILeasingCalculatorRouteState } from "./leasing-calculator-route-state-interface";

@Component({
  selector: "kt-leasing-calculator",
  templateUrl: "./leasing-calculator.component.html",
  styleUrls: ["./leasing-calculator.component.scss"],
})
export class LeasingCalculatorComponent implements OnInit, OnDestroy {
  @Input() entity;
  vehicleMileageSubject: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  @Input() entityType;
  @Input() totalRegistrationTax; // from vehicle valuation step, if recalculated
  @Output() contractCalculated: EventEmitter<any> = new EventEmitter<any>();
  @Input() selectedTabIndex;
  addAnotherProduct: boolean = false;
  // Public properties
  product: LeasingCalculatorModel;
  contractTypes = EntityTypes;
  filteredProducts: Observable<any[]>;
  productForm: FormGroup;
  leasingTypes: any[];
  settingValues: any;
  // contractTypes: any[];
  hasFormErrors: boolean = false;
  calculations: any = {};
  lastFieldTouched: any = "";
  @Input() isReadonly: boolean = false;

  disable_down_payment: boolean = false;
  disable_deposit: boolean = false;
  disable_salvage_value: boolean = false;
  disable_interest_rate: boolean = false;
  disable_markup_interest_rate: boolean = false;
  disable_leasing_payment: boolean = false;

  view_down_payment: boolean = false;
  view_deposit: boolean = false;
  view_salvage_value: boolean = false;
  view_interest_rate: boolean = false;
  view_variable_interest_rate: boolean = false;
  view_leasing_payment: boolean = false;

  gotChangesFromSalvageValue = false;

  @ViewChild("startValue") startValueInput: ElementRef;

  errorMsgs: any = {}; //Back-end errors
  // Private password
  private componentSubscriptions: Subscription[] = [];
  // sticky portlet header margin
  private headerMargin: number;
  viewLoading = false;
  routerUrl: any;
  isFirstCalculation: boolean = true;
  callingNo: any = 0;
  defaultPlateType: any = 0;
  licensePlateTypes: any[];
  down_payment_modified: boolean = false;
  investment: number;
  investmentTitle: string;
  routeState: ILeasingCalculatorRouteState | undefined;
  gettingSettings: boolean = false;
  constructor(
    private productFB: FormBuilder,
    private typesUtilsService: TypesUtilsService,
    private productsService: SharedService,
    private permissionsService: NgxPermissionsService,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private settingPermissionsService: PermissionsService
  ) {}

  ngOnInit() {
    this.getRouteState();
    this.permissionsService.permissions$.subscribe((res) => {
      if (this.entityType === EntityType.CONTRACT) {
        this.view_down_payment = res.view_contract_down_payment ? true : false;
        this.view_deposit = res.view_contract_deposit ? true : false;
        this.view_salvage_value = res.view_contract_salvage_value ? true : false;
        this.view_interest_rate = res.view_contract_interest_rate ? true : false;
        this.view_variable_interest_rate = res.view_contract_variable_interest_rate ? true : false;
        this.view_leasing_payment = res.view_contract_leasing_payment ? true : false;
        this.disable_down_payment = res.view_contract_down_payment && !res.edit_contract_down_payment ? true : false;
        this.disable_deposit = res.view_contract_deposit && !res.edit_contract_deposit ? true : false;
        this.disable_salvage_value = res.view_contract_salvage_value && !res.edit_contract_salvage_value ? true : false;
        this.disable_interest_rate = res.view_contract_interest_rate && !res.edit_contract_interest_rate ? true : false;
        this.disable_markup_interest_rate =
          res.view_contract_variable_interest_rate && !res.edit_contract_markup_interest_rate ? true : false;
        this.disable_leasing_payment =
          res.view_contract_leasing_payment && !res.edit_contract_leasing_payment ? true : false;
      } else {
        this.view_down_payment = res.view_offer_down_payment ? true : false;
        this.view_deposit = res.view_offer_deposit ? true : false;
        this.view_salvage_value = res.view_offer_salvage_value ? true : false;
        this.view_interest_rate = res.view_offer_interest_rate ? true : false;
        this.view_variable_interest_rate = res.view_offer_variable_interest_rate ? true : false;
        this.view_leasing_payment = res.view_offer_leasing_payment ? true : false;
        this.disable_down_payment = res.view_offer_down_payment && !res.edit_offer_down_payment ? true : false;
        this.disable_deposit = res.view_offer_deposit && !res.edit_offer_deposit ? true : false;
        this.disable_salvage_value = res.view_offer_salvage_value && !res.edit_offer_salvage_value ? true : false;
        this.disable_interest_rate = res.view_offer_interest_rate && !res.edit_offer_interest_rate ? true : false;
        this.disable_markup_interest_rate =
          res.view_offer_variable_interest_rate && !res.edit_offer_markup_interest_rate ? true : false;
        this.disable_leasing_payment = res.view_offer_leasing_payment && !res.edit_offer_leasing_payment ? true : false;
      }
    });
    const vehicleMileageSubscription = this.vehicleMileageSubject.subscribe((res) => {
      this.productForm?.get("vehicle_mileage").setValue(res);
    });
    this.componentSubscriptions.push(vehicleMileageSubscription);
    const licensePlateTypesSubscription = this.productsService.getLookups("license_plate_type").subscribe((res) => {
      this.licensePlateTypes = res.data;
      this.defaultPlateType = this.licensePlateTypes.filter(
        (plateType) => plateType.code == LicensePlatesTypes.WHITE
      )[0]?.id;
      if (!this.product.license_plate_type)
        this.productForm.controls["license_plate_type"].setValue(
          this.licensePlateTypes.filter((el) => el.code == LicensePlatesTypes.WHITE)[0].code
        );
    });
    this.componentSubscriptions.push(licensePlateTypesSubscription);

    const { asset, asset_details, vehicle_market_price_set, contract_status, contract_step, ...product } = this.entity;
    if (product.basic_products) {
      product.basic_products.forEach((_product) => {
        if (_product.code == "leasing_payment") product.leasing_payment = _product.amount_excl_vat_cents;
        if (_product.code == "down_payment") product.down_payment = _product.amount_excl_vat_cents;
        if (_product.code == "deposit") product.deposit = _product.amount_excl_vat_cents;
      });
    }

    this.loadProduct({
      ...product,
      start_date: product.start_date ?? null,
      first_registration_date:
        this.entity && this.entity.asset_details && this.entity.asset_details.first_registration_date
          ? new Date(this.entity.asset_details.first_registration_date)
          : "",
      payment_method: "interest_rate",
      salvage_value_cents: product.salvage_value_cents ? product.salvage_value_cents : null,
      deposit: product.deposit || 0,
      down_payment: product.down_payment || 0,
      down_payment_modified: product.down_payment_modified || this.down_payment_modified,
    });
    const leasingTypesSubscription = this.productsService.getLookups("leasing_types").subscribe((res) => {
      this.leasingTypes = res.data;
      this.setDefaultValues();
    });
    this.componentSubscriptions.push(leasingTypesSubscription);

    window.onload = () => {
      const style = getComputedStyle(document.getElementById("kt_header"));
      this.headerMargin = parseInt(style.height, 0);
    };
  }

  getRouteState() {
    this.routerUrl = this.router.url;
    this.routeState = history.state;
  }

  returnOfInvestmentData(res) {
    if (res) {
      this.investment = res.key_figures?.filter((m: { code: string }) => m.code === "Return_of_investment")[0]?.value;
      this.investmentTitle = res.key_figures?.filter(
        (m: { code: string }) => m.code === "Return_of_investment"
      )[0]?.name;
    } else {
      this.investment = 0.0;
      this.investmentTitle = "Return of investment";
    }
  }

  @HostListener("document:focusout", ["$event"])
  onFocusOut(e: Event): void {
    if (e.target["localName"] === "input" && e.target["name"] !== "is_variable_interest") {
      this.calculateLeasing();
    }
  }

  loadProduct(_product) {
    this.product = _product;
    this.initProduct();
    this.calculateLeasing();
    this.cdr.detectChanges();
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    // Update vehicle details because contract is changed
    if (changes["entity"] && !changes["entity"].firstChange && changes["entity"].currentValue) {
      this.entity = {
        ...this.entity,
        asset_details: changes["entity"].currentValue.asset_details,
      };
      if (this.productForm) {
        this.productForm
          .get("first_registration_date")
          .setValue(new Date(this.entity.asset_details?.first_registration_date));
      }
    }
    if (
      changes["totalRegistrationTax"] &&
      !changes["totalRegistrationTax"].firstChange &&
      changes["totalRegistrationTax"].currentValue
    ) {
      if (this.productForm && this.productForm.get("registration_tax_cents")) {
        this.productForm.get("registration_tax_cents").setValue(changes["totalRegistrationTax"].currentValue);
      }
    }

    if (changes["selectedTabIndex"] && changes["selectedTabIndex"].currentValue == 3) {
      this.standstillIsSelected();
    }
  }

  ngOnDestroy() {
    if (this.componentSubscriptions) {
      this.componentSubscriptions.forEach((subscription) => {
        subscription.unsubscribe();
      });
    }
  }

  initProduct() {
    this.createForm();
  }

  onSalvageValueChange() {
    this.gotChangesFromSalvageValue = true;
    this.setLastTouchedFieldName("salvage_value_cents");
  }

  getVehicleLatestMileages() {
    return this.entity.asset_details?.latest_mileage;
  }

  createForm() {
    this.productForm = this.productFB.group({
      leasing_type: [{ value: this.product.leasing_type, disabled: this.isReadonly }, Validators.required],
      // contract_type: [{ value: this.product.contract_type, disabled: this.isReadonly }, Validators.required],
      period: [
        { value: this.product.period, disabled: this.isReadonly },
        Validators.compose([Validators.required, Validators.max(999)]),
      ],
      start_date: [{ value: this.product.start_date, disabled: this.isReadonly }],
      license_plate_type: [this.product.license_plate_type],
      kilometer_limit: [
        {
          value: this.product.kilometer_limit,
          disabled: this.isReadonly,
        },
      ],
      first_registration_date: [
        {
          value: this.product.first_registration_date,
          disabled: this.isReadonly,
        },
        Validators.required,
      ],
      //=======================================================================
      deprecation: [
        {
          value: this.product.deprecation,
          disabled: this.isReadonly,
        },
      ],
      //=======================================================================
      vehicle_price_excl_vat_cents: [
        {
          value: this.product.vehicle_price_excl_vat_cents,
          disabled: this.isReadonly,
        },
        Validators.required,
      ],
      vehicle_mileage: [
        {
          value: this.getVehicleLatestMileages(),
          disabled: this.isReadonly,
        },
      ],
      start_value_cents: [
        { value: this.product.start_value_cents, disabled: this.isReadonly },
        Validators.compose([Validators.required, Validators.pattern(/[0-9]||^[0-9]+(\+\-\*\/)+[0-9]$/g)]),
      ],
      registration_tax_cents: [
        {
          value: this.product.registration_tax_cents,
          disabled: this.product.leasing_type == EntityLeasingTypes.STANDSTILL,
        },
        Validators.required,
      ],
      down_payment: [
        {
          value: this.product.down_payment,
          disabled: this.isReadonly || this.disable_down_payment,
        },
        Validators.required,
      ],
      deposit: [
        {
          value: this.product.deposit,
          disabled: this.isReadonly || this.disable_deposit,
        },
        Validators.required,
      ],
      salvage_value_cents: [
        {
          value: this.product.salvage_value_cents,
          disabled: this.isReadonly || this.disable_salvage_value,
        },
      ],
      private_tax_cents: [
        {
          value: this.product.private_tax_cents,
          disabled: this.isReadonly,
        },
      ],
      profit_on_vehicle_cents: [
        {
          value: this.product.profit_on_vehicle_cents,
          disabled: true,
        },
      ],
      leasing_payment: [
        {
          value: this.product.leasing_payment,
          disabled: this.isReadonly || this.disable_leasing_payment,
        },
      ],
      interest_rate: [
        {
          value: this.product.interest_rate,
          disabled: this.isReadonly || this.disable_interest_rate,
        },
      ],
      markup_interest_rate: [
        {
          value: this.product.markup_interest_rate,
          disabled: this.isReadonly || this.disable_markup_interest_rate,
        },
      ],
      payment_method: [
        {
          value: this.product.payment_method,
          disabled: this.isReadonly,
        },
      ], // radio button
      commission_cents: [
        {
          value: this.product.commission_cents,
          disabled: this.isReadonly,
        },
      ],
      is_variable_interest: [
        {
          value: this.product.is_variable_interest,
          disabled: this.isReadonly,
        },
      ],
      split_leasing_private_share: [
        {
          value: this.product.split_leasing_private_share,
          disabled: this.isReadonly,
        },
      ],
      split_leasing_corporate_share: [
        {
          value: this.product.split_leasing_corporate_share,
          disabled: true,
        },
      ],
      products: this.productFB.array([]),
      add_on_product: [{ value: "", disabled: this.isReadonly }],
    });

    const payload =
      this.entityType === EntityType.CONTRACT ? { contract_id: this.entity.id } : { offer_id: this.entity.id };

    if (this.settingPermissionsService.canUseSettings()) {
      this.productsService.getVariableInterest(payload).subscribe((res) => {
        if (res.data) {
          this.settingValues = res.data;
          if (!this.product.markup_interest_rate)
            this.productForm.controls.markup_interest_rate.setValue(this.settingValues.value["markup_interest_rate"]);
        }
      });
    }

    this.productForm.get("vehicle_price_excl_vat_cents").valueChanges.subscribe((price) => {
      (<FormArray>this.productForm.get("products")).controls.forEach((productRow: FormGroup, index) => {
        if (
          productRow.controls &&
          productRow.controls.checked &&
          productRow.controls.checked.value &&
          productRow.controls.amount &&
          productRow.controls.amount.pristine
        ) {
          if (productRow.controls.code && productRow.controls.id && productRow.controls.id.value) {
            const productCode = productRow.controls.code && productRow.controls.code.value;
            if (
              productCode == "insurance_yearly" ||
              productCode == "insurance_monthly" ||
              productCode == "used_car_insurance"
            ) {
              this.productsService
                .getInsurancePrice(productRow.controls.id.value, {
                  vehicle_type_id: this.entity?.asset_details?.vehicle_type_id,
                  vehicle_price: price || 0,
                  upper_bound_horsepower: this.entity?.asset_details?.horse_power,
                  customer_id: this.entity?.customer?.id,
                  leasing_type_id: this.productForm.get("leasing_type").value?.id,
                })
                .subscribe((res) => {
                  productRow.controls.amount.setValue((res.data && res.data.price_excl_vat_cents) || 0);
                });
            }
          }
        }
      });
      if (this.disable_down_payment || this.disable_deposit) {
        // Get default values for the 5 fields from Extend me endpoint * vehicle price value
        const extendMeSubscription = this.productsService.extendMe().subscribe((res) => {
          if (this.disable_down_payment)
            this.productForm.controls.down_payment.setValue(
              res.data.settings.down_payment_percent * this.productForm.controls.vehicle_price_excl_vat_cents.value
            );

          if (this.disable_deposit)
            this.productForm.controls.deposit.setValue(
              res.data.settings.deposit_percent * this.productForm.controls.vehicle_price_excl_vat_cents.value
            );
        });
        this.componentSubscriptions.push(extendMeSubscription);
      }
    });

    this.product.products.forEach((product) => {
      this.pushToAddOnProducts(product.product, true, product.amount_excl_vat_cents);
    });

    const productsSubscription = this.productsService
      .getProductsList({
        is_available_in_calculator: 1,
        is_visible_in_calculator: 1,
        is_payment_type: 1,
      })
      .subscribe((res) => {
        res.data.forEach((product) => {
          const contractProduct = this.product.products.find((p) => +p.product_id == +product.id);
          if (!contractProduct) {
            if (product.is_standard) {
              this.pushToAddOnProducts(product, true, product.amount_excl_vat_cents);
            } else {
              this.pushToAddOnProducts(product, false, 0);
            }
          }
        });
      });
    this.componentSubscriptions.push(productsSubscription);

    this.filteredProducts = this.productForm.get("add_on_product").valueChanges.pipe(
      startWith(""),
      debounceTime(200),
      switchMap((value) => {
        if (typeof value == "string") {
          return this.productsService.getProductsList({
            is_available_in_calculator: 1,
            is_visible_in_calculator: 0,
            is_payment_type: 1,
            q: value,
          });
        } else return of([]);
      }),
      map((res) => res.data)
    );

    if (
      (!this.product.interest_rate && !this.routerUrl.includes("calculator/edit")) ||
      this.routeState?.renewingEntity
    ) {
      this.gettingSettings = true;
      const extendMeSubscription = this.productsService.extendMe().subscribe((res) => {
        this.gettingSettings = false;
        if (res) {
          this.productForm.get("interest_rate").setValue(res.settings.interest_rate);
          this.productForm.get("deprecation").setValue(res.settings.depreciation_annual_rate * 100);
        }
        if (this.routeState?.renewingEntity) {
          this.setLastTouchedFieldName("deprecation");
          this.calculateLeasing(false, () => this.calculateLeasing());
        }
      });
      this.componentSubscriptions.push(extendMeSubscription);
    }
    if (!this.product.registration_tax_cents) {
      if (this.entity.vehicle_market_price_set && this.entity.vehicle_market_price_set.registration_tax_cents) {
        this.productForm
          .get("registration_tax_cents")
          .setValue(this.entity.vehicle_market_price_set.registration_tax_cents);
      }
    }
    this.setDefaultValues();

    const splitLeasingShareSubscription = this.productForm
      .get("split_leasing_private_share")
      .valueChanges.subscribe((res) => {
        this.productForm.get("split_leasing_corporate_share").setValue(100 - parseFloat(res));
      });
    this.componentSubscriptions.push(splitLeasingShareSubscription);
  }

  /**
   * Reset pricing fields
   * to zero on removing
   * input value
   * LEMA-1226
   * @param form value
   */
  stringInputError: boolean;
  resetPricingFields(value) {
    // this.calcStartValue();
    const pricingFields = [
      "registration_tax_cents",
      "salvage_value_cents",
      "commission_cents",
      "kilometer_limit",
      "private_tax_cents",
      "leasing_payment",
      "interest_rate",
      "down_payment",
      "deposit",
    ];
    pricingFields.forEach((field) => {
      if (value[field] === null) {
        this.productForm?.get(field).patchValue(0);
      }
    });
  }

  standstillIsSelected() {
    const leasingType = this.productForm?.get("leasing_type")?.value;
    if (leasingType === EntityLeasingTypes.STANDSTILL) {
      let noPlateSelection = this.licensePlateTypes.filter(
        (plateType) => plateType.code === LicensePlatesTypes.NO_PLATE
      )[0];
      this.productForm.controls.license_plate_type.setValue(noPlateSelection.code);
      this.productForm?.get("registration_tax_cents").setValue(0);
      this.productForm?.get("registration_tax_cents").disable();
    } else this.productForm?.get("registration_tax_cents")?.enable();
    if (this.productForm?.get("leasing_type").dirty) {
      this.productForm?.get("products").value.forEach((v, i) => {
        this.addOnProductChanged({ checked: v.checked }, i, v);
      });
    }
  }

  setDefaultValues() {
    if (
      !this.product.leasing_type &&
      this.productForm &&
      this.productForm.get("leasing_type") &&
      !this.productForm.get("leasing_type").value
    ) {
      if (this.leasingTypes && this.leasingTypes.length > 0) {
        let defaultType = this.leasingTypes.find((l) => l.code == EntityLeasingTypes.FINANCIAL);
        this.productForm.get("leasing_type").setValue(defaultType?.code);
      }
    }
  }

  calculateLeasing(calculateEntity = false, completeCallback?: () => void) {
    // To reduce the number of sent requests
    if (
      !this.gettingSettings &&
      this.productForm &&
      this.productForm.get("leasing_type").value &&
      this.productForm.get("period").value &&
      this.productForm.get("vehicle_price_excl_vat_cents").value
    ) {
      let payload = {
        ...this.prepareProduct(),
        down_payment_modified: this.product.down_payment_modified || this.down_payment_modified,
      };
      delete payload.vehicle_mileage;
      const leasingCalculatorSubscription = this.productsService
        .entityLeasingCalculator(payload, this.entity.id, this.entityType)
        .subscribe({
          next: (res) => {
            this.afterCalculation(res);
            this.cdr.markForCheck();
            if (calculateEntity) {
              this.onSubmit();
            }
          },
          error: (err) => {
            this.cdr.markForCheck();
            if (err.error) {
              this.errorMsgs = err.error.errors;
              this.cdr.markForCheck();
            }
          },
          complete: completeCallback,
        });

      this.componentSubscriptions.push(leasingCalculatorSubscription);
    }
  }

  afterCalculation(res) {
    this.returnOfInvestmentData(res.data);

    const response = res.data;
    if (response.first_payments) {
      this.calculations["first_payments"] = response.first_payments;
    }
    if (response.monthly_payments) {
      this.calculations["monthly_payments"] = response.monthly_payments;
    }
    if (response.profit) {
      this.calculations["profit"] = {
        ...response.profit,
        profit_on_vehicle_cents: response.profit_on_vehicle_cents,
        setup_fees: response.setup_fees,
      };
    }
    if (response.key_figures) {
      this.calculations["key_figures"] = response.key_figures;
      // if (this.disable_salvage_value) {
      let salvage_value = response.key_figures.find((k) => k.code == "salvage_value");
      if (salvage_value.value && this.productForm.get("salvage_value_cents")) {
        if (salvage_value.value !== this.productForm.get("salvage_value_cents").value) {
          this.productForm.get("salvage_value_cents").setValue(salvage_value.value);
        }
      }
      // }
    }

    this.productForm.get("down_payment").setValue(response.down_payment);

    if (response.corporate_key_figures) {
      this.calculations["corporate_key_figures"] = response.corporate_key_figures;
    }
    if (
      response.interest_rate &&
      this.productForm.controls.interest_rate.value !== response.interest_rate &&
      !this.productForm.controls.is_variable_interest?.value
    ) {
      this.productForm.get("interest_rate").setValue(response.interest_rate);
    }
    if (this.productForm.get("leasing_payment")) {
      if (response.leasing_payment !== this.productForm.get("leasing_payment").value) {
        this.productForm.get("leasing_payment").setValue(response.leasing_payment);
      }
    }

    if (response.profit_on_vehicle_cents !== this.productForm.get("profit_on_vehicle_cents")?.value) {
      this.productForm.get("profit_on_vehicle_cents").setValue(response.profit_on_vehicle_cents);
    }
    this.productForm.get("deprecation").setValue(response.deprecation);

    this.productForm.get("salvage_value_cents").setValue(response.salvage_value_cents);

    this.errorMsgs = {};
  }
  pushToAddOnProducts(product, isChecked, amount) {
    (<FormArray>this.productForm.get("products")).push(
      this.productFB.group({
        id: [product.id, Validators.required],
        checked: [isChecked, Validators.required],
        name: [product.name, Validators.required],
        code: [product.code],
        price_excl_vat_cents: [product.price_excl_vat_cents],
        amount: [{ value: amount, disabled: !isChecked }, Validators.required],
      })
    );
    const entityProduct = this.product.products.find((p) => p.product_id === product.id);
    if (isChecked && !entityProduct) {
      const lastIndex = this.productForm.get("products").value.length;
      this.addOnProductChanged({ checked: true }, lastIndex - 1, product);
    }
  }

  addOnProductChanged($event, index, product) {
    if ($event.checked) {
      (<FormGroup>(<FormArray>this.productForm.get("products")).controls[index]).controls.amount.enable();
      if (
        product.code == "insurance_yearly" ||
        product.code == "insurance_monthly" ||
        product.code == "used_car_insurance"
      ) {
        this.productsService
          .getInsurancePrice(product.id, {
            vehicle_type_id: this.entity?.asset_details?.vehicle_type_id,
            vehicle_price: this.productForm.get("vehicle_price_excl_vat_cents").value || 0,
            upper_bound_horsepower: this.entity?.asset_details?.horse_power,
            customer_id: this.entity?.customer?.id,
            leasing_type: this.productForm.get("leasing_type").value,
          })
          .subscribe((res) => {
            (<FormGroup>(<FormArray>this.productForm.get("products")).controls[index]).controls.amount.setValue(
              (res.data && res.data.price_excl_vat_cents) || 0
            );
          });
      } else {
        const entityProduct = this.product.products.find((p) => p.product_id === product.id);
        const amount = entityProduct ? entityProduct.amount_excl_vat_cents : product.price_excl_vat_cents;
        (<FormGroup>(<FormArray>this.productForm.get("products")).controls[index]).controls.amount.setValue(amount);
      }
    } else {
      (<FormGroup>(<FormArray>this.productForm.get("products")).controls[index]).controls.amount.disable();
      (<FormGroup>(<FormArray>this.productForm.get("products")).controls[index]).controls.amount.setValue(0);
    }
  }

  addNewAddonProduct() {
    const product = this.productForm.get("add_on_product").value;
    const index = this.productForm.get("products").value.findIndex((p) => +p.id == +product.id);
    if (index == -1) {
      this.pushToAddOnProducts(product, true, 0);
      this.productForm.get("add_on_product").setValue(null);
    }
  }

  setLastTouchedFieldName(lastFieldTouched) {
    this.lastFieldTouched = lastFieldTouched;
  }

  onSubmit() {
    this.hasFormErrors = false;
    const controls = this.productForm.controls;

    /** check form */
    if (this.productForm.invalid) {
      Object.keys(controls).forEach((controlName) => controls[controlName].markAllAsTouched());

      this.hasFormErrors = true;
      return;
    }

    //tslint:disable-next-line:prefer-const
    let product: LeasingCalculatorModel = this.prepareProduct();
    if (!controls["start_date"].value || (controls["start_date"].value && controls["start_date"].value == null)) {
      product.start_date = null;
    }
    // key_figures is for calculate contract endpoint not calculator
    if (!product.key_figures && this.calculations && this.calculations.key_figures) {
      product.key_figures = this.calculations.key_figures.map((key_figure) => ({
        id: key_figure.id,
        key_figure_amount_cents: key_figure.value,
      }));
    }

    // corporate_key_figures is for calculate contract endpoint not calculator
    if (!product.corporate_key_figures && this.calculations && this.calculations.corporate_key_figures) {
      product.corporate_key_figures = this.calculations.corporate_key_figures.map((key_figure) => ({
        id: key_figure.id,
        key_figure_amount_cents: key_figure.value,
      }));
    }

    this.calculateContract(product);
  }

  calculateContract(payload) {
    const createProductSubscription = this.productsService
      .calculateEntity(payload, this.entity.id, this.entityType)
      .subscribe(
        (res) => {
          this.viewLoading = false;
          this.gotChangesFromSalvageValue = false;
          this.contractCalculated.emit({
            ...this.calculations,
            ...res.data,
          });
          this.entity = {
            ...this.entity,
            ...res.data,
          };
          this.errorMsgs = {};
          this.cdr.markForCheck();
        },
        (err) => {
          this.viewLoading = false;
          if (err.error) {
            this.errorMsgs = err.error.errors;
            this.cdr.markForCheck();
            this.hasFormErrors = true;
          }
        }
      );
    this.componentSubscriptions.push(createProductSubscription);
  }

  /**
   * Returns object for saving
   */
  prepareProduct(): LeasingCalculatorModel {
    const controls = { ...this.productForm.controls };

    let product: LeasingCalculatorModel = new LeasingCalculatorModel();

    Object.keys(controls).forEach((key) => {
      if (controls[key] && controls[key].value !== null && controls[key].value !== undefined) {
        product[key] = controls[key].value;
      }
    });
    if (!controls["start_date"].value) {
      product.start_date = this.typesUtilsService.formatDate(moment(), "dd-MM-yyyy");
    } else {
      product.start_date = this.typesUtilsService.formatDate(controls["start_date"].value, "dd-MM-yyyy");
    }
    if (controls["down_payment"].touched && controls["down_payment"].dirty) {
      this.product.down_payment_modified = true;
    }
    product.first_registration_date = controls["first_registration_date"].value
      ? this.typesUtilsService.formatDate(controls["first_registration_date"].value, "dd-MM-yyyy")
      : null;
    product.type = this.entityType == "contract" ? this.entity.contract_type : this.entity.type;
    product.leasing_type = controls["leasing_type"].value ?? null;

    //Create mode
    if (this.routerUrl.includes("calculator/add")) {
      if (!this.gotChangesFromSalvageValue) {
        delete product.salvage_value_cents;
      }
    }
    // Edit mode
    // if (this.routerUrl.includes("calculator/edit")) {
    // 	if (!product.salvage_value_cents) {
    // 		product.salvage_value_cents = 0;
    // 	}
    // }

    if (this.entity && this.entity.asset_details) {
      product.vehicle_id = this.entity.asset_details.id;
    }

    if (controls["products"].value && controls["products"].value.length > 0) {
      product.products = controls["products"].value
        .filter((p) => p.checked)
        .map((p: any) => {
          return {
            id: p.id,
            amount: p.amount,
          };
        });
    } else {
      delete product.products;
    }
    if (this.lastFieldTouched === "deprecation") {
      delete product.salvage_value_cents;
    } else if (this.lastFieldTouched === "salvage_value_cents") {
      delete product.deprecation;
    }
    delete product.add_on_product; // Remove add_on_product as it's just for adding new products to products control
    if (
      this.lastFieldTouched == "interest_rate" ||
      this.lastFieldTouched == "is_variable_interest" ||
      this.lastFieldTouched == "markup_interest_rate"
    ) {
      delete product.leasing_payment;
    } else if (this.lastFieldTouched == "leasing_payment") {
      delete product.interest_rate;
    } else if (
      this.routerUrl.includes("calculator/edit") &&
      this.entityType == EntityType.CONTRACT &&
      !this.routeState?.renewingEntity
    ) {
      delete product.interest_rate;
    } else if (this.routerUrl.includes("calculator/edit") && this.entityType == EntityType.OFFER) {
      delete product.leasing_payment;
    }

    if (this.disable_interest_rate && this.disable_leasing_payment) {
      delete product.leasing_payment;
    }

    if (this.disable_markup_interest_rate && this.disable_leasing_payment) {
      delete product.leasing_payment;
    }

    if (!this.view_variable_interest_rate) {
      delete product.markup_interest_rate;
      delete product.is_variable_interest;
    }

    // if(product.payment_method == 'interest_rate'){
    // 	delete product.leasing_payment;
    // }
    // if(product.payment_method == 'leasing_payment'){
    // 	delete product.interest_rate;
    // }
    delete product.payment_method; // Remove as it only for choose `leasing_payment` or `interest_rate`

    if (this.entityType === EntityType.CONTRACT) product.is_edit = 1;
    else delete product.is_edit;

    if (!this.productForm.controls.is_variable_interest.value) {
      delete product.markup_interest_rate;
    }

    product.license_plate_type = controls["license_plate_type"].value;
    return product;
  }

  displayFn(product: any): string {
    return product && product.name ? product.name : "";
  }

  compareFn(obj1: any, obj2: any): boolean {
    return (obj1?.code === obj2?.code && obj1?.code && obj2?.code) || obj1 === obj2;
  }
  /**
   * Close alert
   *
   * @param $event
   */
  onAlertClose($event) {
    this.hasFormErrors = false;
  }

  calcStartValue() {
    let startValue = this.startValueInput.nativeElement.value as string;
    let trimmedStartValue = "";
    for (let i = 0; i < startValue.length; i++) {
      if (startValue[i] === " ") {
        continue;
      } else {
        trimmedStartValue += startValue[i];
      }
    }
    let result: string | number;
    if (
      trimmedStartValue.match(/[A-za-z]|[0-9][A-za-z]/g) ||
      trimmedStartValue.match(/^[\+\-\*\/]{1,10}|[\+\-\*\/]{2,10}/g) ||
      trimmedStartValue.match(/^[0-9]+[\+\-\*\/]{1,10}$/g)
    ) {
      this.productForm.controls.start_value_cents.setErrors({ pattern: true });
    } else {
      this.productForm.controls.start_value_cents.setErrors({ pattern: false });
      result = eval(trimmedStartValue);
      this.productForm.get("start_value_cents").setValue(result);
    }
  }

  checkVariableInterest() {
    this.lastFieldTouched = "is_variable_interest";
    this.resetPricingFields(this.productForm.value);
    this.calculateLeasing();
  }
}
