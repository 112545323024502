<kt-portlet class="shared-component">
  <kt-portlet-body>
    <div *ngIf="product">
      <kt-alert
        (close)="onAlertClose($event)"
        *ngIf="hasFormErrors"
        [duration]="10000"
        [showCloseButton]="true"
        type="warn"
      >
        {{ "GENERAL.FORM_ERROR_MESSAGE" | translate }}
      </kt-alert>
      <form [ngClass]="{ 'add-vehicle-view': !id }" class="kt-form kt-form--group-seperator-dashed">
        <div [formGroup]="productForm" class="kt-form__section kt-form__section--first">
          <div class="row">
            <div class="col-md-6">
              <div class="kt-form__group">
                <div class="row">
                  <div class="col-md-6 kt-margin-bottom-10-mobile">
                    <label class="mat-input-label">{{
                      "VEHICLE.ADD.FIELDS.VEHICLE_TYPE.PLACEHOLDER" | translate
                    }}</label>
                    <mat-form-field
                      [ngClass]="{
                        'mat-form-field-invalid': errorMsgs['vehicle_type_id']
                      }"
                      class="mat-form-field-fluid"
                    >
                      <mat-select
                        formControlName="vehicle_type_id"
                        placeholder="{{ 'VEHICLE.ADD.FIELDS.VEHICLE_TYPE.PLACEHOLDER' | translate }}"
                      >
                        <mat-option *ngFor="let vehicleType of vehicleTypes" [value]="vehicleType.id"
                          >{{ vehicleType.name }}
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="productForm.controls.vehicle_type_id.hasError('required')">
                        <span [innerHTML]="'VEHICLE.ADD.FIELDS.VEHICLE_TYPE.ERROR_MSG' | translate"></span>
                      </mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="errorMsgs['vehicle_type_id']">{{ errorMsgs["vehicle_type_id"][0] }} </mat-error>
                  </div>
                  <div class="col-md-6 kt-margin-bottom-10-mobile">
                    <label class="mat-input-label">{{ "VEHICLE.ADD.FIELDS.MAKE.PLACEHOLDER" | translate }} </label>
                    <!-- testing directive -->
                    <mat-form-field
                      [ngClass]="{
                        'mat-form-field-invalid': errorMsgs['make_id']
                      }"
                      class="example-full-width searchable"
                    >
                      <span [inlineSVG]="'./assets/media/icons/magnify.svg'" class="kt-svg-icon search-icon"></span>
                      <input
                        [matAutocomplete]="auto"
                        aria-label="Number"
                        formControlName="vehicle_make"
                        matInput
                        placeholder="{{ 'VEHICLE.ADD.FIELDS.MAKE.PLACEHOLDER' | translate }}"
                        tab-directive
                        type="text"
                      />
                      <mat-autocomplete
                        #auto="matAutocomplete"
                        (optionSelected)="fetchMakeModels(true)"
                        [displayWith]="displayFn"
                      >
                        <mat-option *ngFor="let vehicleMake of filteredMakes | async" [value]="vehicleMake">
                          {{ vehicleMake.name }}
                        </mat-option>
                      </mat-autocomplete>
                      <mat-error *ngIf="productForm.controls.vehicle_make.hasError('required')">
                        <span [innerHTML]="'VEHICLE.ADD.FIELDS.MAKE.ERROR_MSG' | translate"></span>
                      </mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="errorMsgs['make_id']">{{ errorMsgs["make_id"][0] }}</mat-error>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 kt-margin-bottom-10-mobile">
                    <label class="mat-input-label">{{ "VEHICLE.ADD.FIELDS.MODEL.PLACEHOLDER" | translate }}</label>
                    <mat-form-field
                      [ngClass]="{
                        'mat-form-field-invalid': errorMsgs['model']
                      }"
                      class="example-full-width searchable"
                    >
                      <span [inlineSVG]="'./assets/media/icons/magnify.svg'" class="kt-svg-icon search-icon"></span>
                      <input
                        [matAutocomplete]="modelAutocomplete"
                        aria-label="Number"
                        formControlName="vehicle_model"
                        matInput
                        placeholder="{{ 'VEHICLE.ADD.FIELDS.MODEL.PLACEHOLDER' | translate }}"
                        tab-directive
                        type="text"
                      />
                      <mat-autocomplete #modelAutocomplete="matAutocomplete" [displayWith]="modelDisplayFn">
                        <mat-option *ngFor="let vehicleModel of filteredModels | async" [value]="vehicleModel">
                          {{ vehicleModel.model }}
                        </mat-option>
                      </mat-autocomplete>
                      <mat-error *ngIf="productForm.controls.vehicle_model.hasError('required')">
                        <span [innerHTML]="'VEHICLE.ADD.FIELDS.MODEL.ERROR_MSG' | translate"></span>
                      </mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="errorMsgs['model']">{{ errorMsgs["model"][0] }}</mat-error>
                  </div>
                  <!-- <div class="col-md-6 kt-margin-bottom-10-mobile">
                                        <label class="mat-input-label">{{'VEHICLE.ADD.FIELDS.MODEL.PLACEHOLDER' | translate}}</label>
                                        <mat-form-field class="mat-form-field-fluid" [ngClass]="{'mat-form-field-invalid' : errorMsgs['model']}">
                                            <input matInput placeholder="{{'VEHICLE.ADD.FIELDS.MODEL.PLACEHOLDER' | translate}}" formControlName="model" type="text">
                                            <mat-error *ngIf="productForm.controls.model.hasError('required')">
                                                <span [innerHTML]="'VEHICLE.ADD.FIELDS.MODEL.ERROR_MSG' | translate"></span>
                                            </mat-error>
                                        </mat-form-field>
                                        <mat-error *ngIf="errorMsgs['model']">{{errorMsgs['model'][0]}}</mat-error>
                                    </div> -->
                </div>
                <div class="row">
                  <div class="col-md-12 kt-margin-bottom-10-mobile">
                    <label class="mat-input-label">{{ "VEHICLE.ADD.FIELDS.TRIM.PLACEHOLDER" | translate }}</label>
                    <mat-form-field
                      [ngClass]="{
                        'mat-form-field-invalid': errorMsgs['trim']
                      }"
                      class="mat-form-field-fluid"
                    >
                      <input
                        formControlName="trim"
                        matInput
                        placeholder="{{ 'VEHICLE.ADD.FIELDS.TRIM.PLACEHOLDER' | translate }}"
                        type="text"
                      />
                      <mat-error *ngIf="productForm.controls.trim.hasError('required')">
                        <span [innerHTML]="'VEHICLE.ADD.FIELDS.TRIM.ERROR_MSG' | translate"></span>
                      </mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="errorMsgs['trim']">{{ errorMsgs["trim"][0] }}</mat-error>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 kt-margin-bottom-10-mobile">
                    <label class="mat-input-label">{{
                      "VEHICLE.ADD.FIELDS.FIRST_REGISTERATION_DATE.PLACEHOLDER" | translate
                    }}</label>
                    <mat-form-field
                      [ngClass]="{
                        'mat-form-field-invalid': errorMsgs['first_registration_date']
                      }"
                      class="mat-form-field-fluid"
                    >
                      <input
                        [matDatepicker]="firstRegistrationPicker"
                        formControlName="first_registration_date"
                        matInput
                        placeholder="{{ 'VEHICLE.ADD.FIELDS.FIRST_REGISTERATION_DATE.PLACEHOLDER' | translate }}"
                        type="text"
                      />
                      <mat-datepicker-toggle [for]="firstRegistrationPicker" matSuffix></mat-datepicker-toggle>
                      <mat-datepicker #firstRegistrationPicker></mat-datepicker>
                      <mat-error *ngIf="productForm.controls.first_registration_date.hasError('required')">
                        <span [innerHTML]="'VEHICLE.ADD.FIELDS.FIRST_REGISTERATION_DATE.ERROR_MSG' | translate"></span>
                      </mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="errorMsgs['first_registration_date']">
                      {{ errorMsgs["first_registration_date"][0] }}</mat-error
                    >
                  </div>
                  <div class="col-md-6 kt-margin-bottom-10-mobile">
                    <label class="mat-input-label">{{ "VEHICLE.ADD.FIELDS.YEAR.PLACEHOLDER" | translate }}</label>
                    <mat-form-field
                      [ngClass]="{
                        'mat-form-field-invalid': errorMsgs['year']
                      }"
                      class="mat-form-field-fluid"
                      ktYearPicker
                    >
                      <input
                        [matDatepicker]="dp"
                        formControlName="year"
                        matInput
                        placeholder="{{ 'VEHICLE.ADD.FIELDS.YEAR.PLACEHOLDER' | translate }}"
                      />
                      <mat-datepicker-toggle [for]="dp" matSuffix></mat-datepicker-toggle>
                      <mat-datepicker #dp (yearSelected)="chosenYearHandler($event, dp)" startView="multi-year">
                      </mat-datepicker>
                      <mat-error *ngIf="productForm.controls.year.hasError('required')">
                        <span [innerHTML]="'VEHICLE.ADD.FIELDS.YEAR.ERROR_MSG' | translate"></span>
                      </mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="errorMsgs['year']">{{ errorMsgs["year"][0] }}</mat-error>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 kt-margin-bottom-10-mobile">
                    <label class="mat-input-label">{{ "VEHICLE.ADD.FIELDS.VIN_NUMBER.PLACEHOLDER" | translate }}</label>
                    <mat-form-field
                      [ngClass]="{
                        'mat-form-field-invalid': errorMsgs['vin_number']
                      }"
                      class="mat-form-field-fluid"
                    >
                      <input
                        formControlName="vin_number"
                        matInput
                        placeholder="{{ 'VEHICLE.ADD.FIELDS.VIN_NUMBER.PLACEHOLDER' | translate }}"
                        type="text"
                      />
                      <mat-error *ngIf="productForm.controls.vin_number.hasError('required')">
                        <span [innerHTML]="'VEHICLE.ADD.FIELDS.VIN_NUMBER.ERROR_MSG' | translate"></span>
                      </mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="errorMsgs['vin_number']">{{ errorMsgs["vin_number"][0] }} </mat-error>
                  </div>
                  <!-- **************      **************** -->
                  <div class="col-md-6 kt-margin-bottom-10-mobile align-self-center">
                    <mat-checkbox formControlName="is_used_vat" name="is_used_vat">
                      {{ "VEHICLE.ADD.FIELDS.IS_USED_VAT.PLACEHOLDER" | translate }}
                    </mat-checkbox>
                    <mat-error *ngIf="errorMsgs['is_used_vat']">{{ errorMsgs["is_used_vat"][0] }}</mat-error>
                  </div>
                </div>
                <div class="row">
                  <!-- *ngIf="!vehicle_valuation" -->
                  <div class="col-md-6 kt-margin-bottom-10-mobile">
                    <label class="mat-input-label">{{ "VEHICLE.ADD.FIELDS.FUEL_TYPE.PLACEHOLDER" | translate }}</label>
                    <mat-form-field
                      [ngClass]="{ 'mat-form-field-invalid': errorMsgs['fuel_type'] }"
                      class="mat-form-field-fluid"
                    >
                      <mat-select
                        formControlName="fuel_type"
                        placeholder="{{ 'VEHICLE.ADD.FIELDS.FUEL_TYPE.PLACEHOLDER' | translate }}"
                      >
                        <mat-option *ngFor="let fuelType of fuelTypes" [value]="fuelType.code">
                          {{ fuelType.name }}</mat-option
                        >
                      </mat-select>
                      <mat-error *ngIf="productForm.controls.fuel_type.hasError('required')">
                        <span [innerHTML]="'VEHICLE.ADD.FIELDS.FUEL_TYPE.ERROR_MSG' | translate"></span>
                      </mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="errorMsgs['fuel_type']">{{ errorMsgs["fuel_type"][0] }} </mat-error>
                  </div>
                  <div
                    [hidden]="productForm.controls.fuel_type.value == FuelTypesEnum.ELECTRIC && !vehicle_valuation"
                    class="col-md-6 kt-margin-bottom-10-mobile"
                  >
                    <label class="mat-input-label">{{
                      "VEHICLE.ADD.FIELDS.FUEL_CONSUMPTION.PLACEHOLDER" | translate
                    }}</label>
                    <mat-form-field
                      no-scroll
                      [ngClass]="{
                        'mat-form-field-invalid': errorMsgs['fuel_consumption']
                      }"
                      class="mat-form-field-fluid"
                    >
                      <input
                        formControlName="fuel_consumption"
                        matInput
                        type="number"
                        placeholder="{{ 'VEHICLE.ADD.FIELDS.FUEL_CONSUMPTION.PLACEHOLDER' | translate }}"
                      />
                      <mat-error *ngIf="productForm.controls.fuel_consumption.hasError('required')">
                        <span [innerHTML]="'VEHICLE.ADD.FIELDS.FUEL_CONSUMPTION.ERROR_MSG' | translate"></span>
                      </mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="errorMsgs['fuel_consumption']"> {{ errorMsgs["fuel_consumption"][0] }}</mat-error>
                  </div>
                </div>
                <div class="row">
                  <div *ngIf="vehicle_valuation" class="col-md-6 kt-margin-bottom-10-mobile">
                    <label class="mat-input-label">{{
                      "VEHICLE.ADD.FIELDS.NEW_PRICE_CENTS.PLACEHOLDER" | translate
                    }}</label>
                    <mat-form-field
                      [ngClass]="{
                        'mat-form-field-invalid': errorMsgs['new_price_cents']
                      }"
                      class="mat-form-field-fluid"
                    >
                      <input
                        currencyMask
                        formControlName="new_price_cents"
                        matInput
                        placeholder="{{ 'VEHICLE.ADD.FIELDS.NEW_PRICE_CENTS.PLACEHOLDER' | translate }}"
                        type="text"
                      />
                      <mat-error *ngIf="productForm.controls.new_price_cents.hasError('required')">
                        <span [innerHTML]="'VEHICLE.ADD.FIELDS.NEW_PRICE_CENTS.ERROR_MSG' | translate"></span>
                      </mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="errorMsgs['new_price_cents']"> {{ errorMsgs["new_price_cents"][0] }}</mat-error>
                  </div>
                  <div *ngIf="vehicle_valuation" class="col-md-6 kt-margin-bottom-10-mobile">
                    <label class="mat-input-label">{{ "VEHICLE.ADD.FIELDS.CONDITION.PLACEHOLDER" | translate }}</label>
                    <mat-form-field
                      [ngClass]="{
                        'mat-form-field-invalid': errorMsgs['vehicle_condition_id']
                      }"
                      class="mat-form-field-fluid"
                    >
                      <mat-select
                        formControlName="vehicle_condition_id"
                        placeholder="{{ 'VEHICLE.ADD.FIELDS.CONDITION.PLACEHOLDER' | translate }}"
                      >
                        <mat-option *ngFor="let vehicleCondition of vehicleConditions" [value]="vehicleCondition.id">
                          {{ vehicleCondition.name }}</mat-option
                        >
                      </mat-select>
                      <mat-error *ngIf="productForm.controls.vehicle_condition_id.hasError('required')">
                        <span [innerHTML]="'VEHICLE.ADD.FIELDS.CONDITION.ERROR_MSG' | translate"></span>
                      </mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="errorMsgs['condition']">{{ errorMsgs["condition"][0] }} </mat-error>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 kt-margin-bottom-10-mobile">
                    <label class="mat-input-label">{{ "VEHICLE.ADD.FIELDS.MARKET.PLACEHOLDER" | translate }}</label>
                    <mat-form-field
                      [ngClass]="{
                        'mat-form-field-invalid': errorMsgs['market_car']
                      }"
                      class="mat-form-field-fluid"
                    >
                      <input
                        currencyMask
                        formControlName="market_car"
                        matInput
                        placeholder="{{ 'VEHICLE.ADD.FIELDS.MARKET.PLACEHOLDER' | translate }}"
                        type="text"
                      />
                      <mat-error *ngIf="productForm.controls.market_car.hasError('required')">
                        <span [innerHTML]="'VEHICLE.ADD.FIELDS.MARKET.ERROR_MSG' | translate"></span>
                      </mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="errorMsgs['market_car']">{{ errorMsgs["market_car"][0] }} </mat-error>
                  </div>
                  <div class="col-md-6 kt-margin-bottom-10-mobile">
                    <label class="mat-input-label">{{ "VEHICLE.ADD.FIELDS.MILEAGE.PLACEHOLDER" | translate }}</label>
                    <mat-form-field
                      [ngClass]="{
                        'mat-form-field-invalid': errorMsgs['mileage_car']
                      }"
                      class="mat-form-field-fluid"
                    >
                      <input
                        formControlName="mileage_car"
                        matInput
                        placeholder="{{ 'VEHICLE.ADD.FIELDS.MILEAGE.PLACEHOLDER' | translate }}"
                        type="text"
                      />
                      <mat-error *ngIf="productForm.controls.mileage_car.hasError('required')">
                        <span [innerHTML]="'VEHICLE.ADD.FIELDS.MILEAGE.ERROR_MSG' | translate"></span>
                      </mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="errorMsgs['mileage_car']">{{ errorMsgs["mileage_car"][0] }} </mat-error>
                  </div>
                </div>
                <div class="row mb-3 kt-margin-bottom-10-mobile align-items-center">
                  <div class="col-md-6">
                    <mat-checkbox formControlName="hasMileagePhoto" name="hasMileagePhoto">
                      {{ "VEHICLE.ADD.FIELDS.MILEAGE.HAS_PHOTO_LABEL" | translate }}
                    </mat-checkbox>
                  </div>
                  <div *ngIf="productForm.get('hasMileagePhoto')?.value" class="col-md-6">
                    <div [class.mat-form-field-invalid]="errorMsgs['image']" class="dropzone-wrapper">
                      <dropzone
                        #mileagePhoto
                        (addedFile)="onAddedFile($event)"
                        (removedFile)="onRemoveFile($event)"
                        [config]="dropZoneConfig"
                        message="Drop your vehicle photo here.."
                      >
                      </dropzone>
                      <mat-error *ngIf="!isMileagePhotoValid">
                        <span
                          style="font-size: 75%"
                          [innerHTML]="'VEHICLE.ADD.FIELDS.MILEAGE.PHOTO_ERROR_MSG' | translate"
                        ></span>
                      </mat-error>
                    </div>
                  </div>
                </div>
                <div
                  *ngIf="productForm.controls.fuel_type.value == FuelTypesEnum.ELECTRIC && vehicle_valuation"
                  class="row"
                >
                  <div class="col-md-6 kt-margin-bottom-10-mobile">
                    <label class="mat-input-label">{{
                      "VEHICLE.ADD.FIELDS.ELECTRIC_CONSUMPTION.PLACEHOLDER" | translate
                    }}</label>
                    <mat-form-field
                      no-scroll
                      [ngClass]="{
                        'mat-form-field-invalid': errorMsgs['electric_consumption']
                      }"
                      class="mat-form-field-fluid"
                    >
                      <input
                        formControlName="electric_consumption"
                        matInput
                        type="number"
                        placeholder="{{ 'VEHICLE.ADD.FIELDS.ELECTRIC_CONSUMPTION.PLACEHOLDER' | translate }}"
                      />
                      <mat-error *ngIf="productForm.controls.electric_consumption.hasError('required')">
                        <span [innerHTML]="'VEHICLE.ADD.FIELDS.ELECTRIC_CONSUMPTION.ERROR_MSG' | translate"></span>
                      </mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="errorMsgs['electric_consumption']">
                      {{ errorMsgs["electric_consumption"][0] }}</mat-error
                    >
                  </div>
                  <div class="col-md-6 kt-margin-bottom-10-mobile">
                    <label class="mat-input-label">{{
                      "VEHICLE.ADD.FIELDS.ELECTRIC_RANGE.PLACEHOLDER" | translate
                    }}</label>
                    <mat-form-field
                      no-scroll
                      [ngClass]="{
                        'mat-form-field-invalid': errorMsgs['electric_range']
                      }"
                      class="mat-form-field-fluid"
                    >
                      <input
                        formControlName="electric_range"
                        matInput
                        type="number"
                        placeholder="{{ 'VEHICLE.ADD.FIELDS.ELECTRIC_RANGE.PLACEHOLDER' | translate }}"
                      />
                      <mat-error *ngIf="productForm.controls.electric_range.hasError('required')">
                        <span [innerHTML]="'VEHICLE.ADD.FIELDS.ELECTRIC_RANGE.ERROR_MSG' | translate"></span>
                      </mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="errorMsgs['electric_range']"> {{ errorMsgs["electric_range"][0] }}</mat-error>
                  </div>
                </div>
                <div class="row">
                  <div *ngIf="vehicle_valuation" class="col-md-6 kt-margin-bottom-10-mobile">
                    <label class="mat-input-label">{{ "VEHICLE.ADD.FIELDS.FULE_NORM.PLACEHOLDER" | translate }}</label>
                    <mat-form-field
                      [ngClass]="{
                        'mat-form-field-invalid': errorMsgs['fuel_norm']
                      }"
                      class="mat-form-field-fluid"
                    >
                      <mat-select
                        formControlName="fuel_norm"
                        placeholder="{{ 'VEHICLE.ADD.FIELDS.FULE_NORM.PLACEHOLDER' | translate }}"
                      >
                        <mat-option *ngFor="let fuelMeasurement of fuelMeasurements" [value]="fuelMeasurement.code">
                          {{ fuelMeasurement.name }}</mat-option
                        >
                      </mat-select>
                      <mat-error *ngIf="productForm.controls.fuel_norm.hasError('required')">
                        <span [innerHTML]="'VEHICLE.ADD.FIELDS.FULE_NORM.ERROR_MSG' | translate"></span>
                      </mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="errorMsgs['fuel_norm']">{{ errorMsgs["fuel_norm"][0] }} </mat-error>
                  </div>

                  <div *ngIf="vehicle_valuation" class="col-md-6 kt-margin-bottom-10-mobile">
                    <label class="mat-input-label">{{ "VEHICLE.ADD.FIELDS.CO2_PER_KM.PLACEHOLDER" | translate }}</label>
                    <mat-form-field
                      no-scroll
                      [ngClass]="{
                        'mat-form-field-invalid': errorMsgs['co2_per_km']
                      }"
                      class="mat-form-field-fluid"
                    >
                      <input
                        formControlName="co2_per_km"
                        matInput
                        type="number"
                        placeholder="{{ 'VEHICLE.ADD.FIELDS.CO2_PER_KM.PLACEHOLDER' | translate }}"
                      />
                      <mat-error *ngIf="productForm.controls.co2_per_km.hasError('required')">
                        <span [innerHTML]="'VEHICLE.ADD.FIELDS.CO2_PER_KM.ERROR_MSG' | translate"></span>
                      </mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="errorMsgs['co2_per_km']"> {{ errorMsgs["co2_per_km"][0] }}</mat-error>
                  </div>
                </div>

                <div class="row">
                  <div *ngIf="vehicle_valuation" class="col-md-6 kt-margin-bottom-10-mobile">
                    <label class="mat-input-label">{{
                      "VEHICLE.ADD.FIELDS.CURB_WEIGHT.PLACEHOLDER" | translate
                    }}</label>
                    <mat-form-field
                      no-scroll
                      [ngClass]="{
                        'mat-form-field-invalid': errorMsgs['curb_weight']
                      }"
                      class="mat-form-field-fluid"
                    >
                      <input
                        formControlName="curb_weight"
                        matInput
                        type="number"
                        placeholder="{{ 'VEHICLE.ADD.FIELDS.CURB_WEIGHT.PLACEHOLDER' | translate }}"
                      />
                      <mat-error *ngIf="productForm.controls.curb_weight.hasError('required')">
                        <span [innerHTML]="'VEHICLE.ADD.FIELDS.CURB_WEIGHT.ERROR_MSG' | translate"></span>
                      </mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="errorMsgs['curb_weight']"> {{ errorMsgs["curb_weight"][0] }}</mat-error>
                  </div>

                  <div *ngIf="vehicle_valuation" class="col-md-6 kt-margin-bottom-10-mobile">
                    <label class="mat-input-label">{{
                      "VEHICLE.ADD.FIELDS.BATTERY_CAPACITY.PLACEHOLDER" | translate
                    }}</label>
                    <mat-form-field
                      no-scroll
                      [ngClass]="{
                        'mat-form-field-invalid': errorMsgs['battery_capacity']
                      }"
                      class="mat-form-field-fluid"
                    >
                      <input
                        formControlName="battery_capacity"
                        matInput
                        type="number"
                        placeholder="{{ 'VEHICLE.ADD.FIELDS.BATTERY_CAPACITY.PLACEHOLDER' | translate }}"
                      />
                      <mat-error *ngIf="productForm.controls.battery_capacity.hasError('required')">
                        <span [innerHTML]="'VEHICLE.ADD.FIELDS.BATTERY_CAPACITY.ERROR_MSG' | translate"></span>
                      </mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="errorMsgs['battery_capacity']"> {{ errorMsgs["battery_capacity"][0] }}</mat-error>
                  </div>

                  <div class="col-md-6 kt-margin-bottom-10-mobile">
                    <label class="mat-input-label">{{
                      "VEHICLE.ADD.FIELDS.HORSE_POWER.PLACEHOLDER" | translate
                    }}</label>
                    <mat-form-field
                      no-scroll
                      [ngClass]="{
                        'mat-form-field-invalid': errorMsgs['horse_power']
                      }"
                      class="mat-form-field-fluid"
                    >
                      <input
                        formControlName="horse_power"
                        matInput
                        type="number"
                        placeholder="{{ 'VEHICLE.ADD.FIELDS.HORSE_POWER.PLACEHOLDER' | translate }}"
                      />
                      <mat-error *ngIf="productForm.controls.horse_power.hasError('required')">
                        <span [innerHTML]="'VEHICLE.ADD.FIELDS.HORSE_POWER.ERROR_MSG' | translate"></span>
                      </mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="errorMsgs['horse_power']"> {{ errorMsgs["horse_power"][0] }}</mat-error>
                  </div>
                  <div class="col-md-6 kt-margin-bottom-10-mobile">
                    <label class="mat-input-label">{{
                      "VEHICLE.ADD.FIELDS.NEXT_INSPECTION.PLACEHOLDER" | translate
                    }}</label>
                    <mat-form-field
                      [ngClass]="{
                        'mat-form-field-invalid': errorMsgs['next_inspection_date']
                      }"
                      class="mat-form-field-fluid"
                    >
                      <input
                        [matDatepicker]="nextInspection"
                        formControlName="next_inspection"
                        matInput
                        placeholder="{{ 'VEHICLE.ADD.FIELDS.NEXT_INSPECTION.PLACEHOLDER' | translate }}"
                        type="text"
                      />
                      <mat-datepicker-toggle [for]="nextInspection" matSuffix></mat-datepicker-toggle>
                      <mat-datepicker #nextInspection></mat-datepicker>
                      <mat-error *ngIf="productForm.controls.next_inspection.hasError('required')">
                        <span [innerHTML]="'VEHICLE.ADD.FIELDS.NEXT_INSPECTION.ERROR_MSG' | translate"></span>
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 kt-margin-bottom-10-mobile align-self-center">
                    <mat-checkbox formControlName="full_reg_tax" name="full_reg_tax">
                      {{ "VEHICLE.ADD.FIELDS.FULL_REG_TAX.PLACEHOLDER" | translate }}
                    </mat-checkbox>
                    <mat-error *ngIf="errorMsgs['full_reg_tax']">{{ errorMsgs["full_reg_tax"][0] }}</mat-error>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </kt-portlet-body>
  <kt-portlet-footer *ngIf="withSubmit" class="entity__footer">
    <div *ngIf="product" class="kt-form__actions kt-form__actions--sm">
      <button
        (click)="onSubmit()"
        *ngIf="!isReadonly"
        class="btn btn-primary"
        color="primary"
        mat-raised-button
        matTooltip="{{ 'GENERAL.SAVE' | translate }}"
      >
        {{ id ? ("VEHICLE.ADD.EDIT_TITLE" | translate) : ("VEHICLE.ADD.ADD_TITLE" | translate) }}
      </button>
    </div>
  </kt-portlet-footer>
</kt-portlet>
