// Angular
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
// RxJS
import { Observable } from "rxjs";

import { API } from "../../../core/_base/crud";
import { IRequestPagination } from "../../../core/_base/crud/interfaces/request-pagination";
import { IResponsePagination } from "../../../core/_base/crud/interfaces/response-pagination";
import { OfferModel } from "./offers.model";
import { IResponse } from "../../../core/_base/crud/interfaces/response-interface";
import { ICreditroCase, IGetCreditroCase } from "../contracts/contract-details/creditro-case/creditro-interfaces";

@Injectable()
export class OffersService {
  constructor(private http: HttpClient) {}

  createProduct(product): Observable<any> {
    return this.http.post<any>(API.offers, product);
  }

  getProductById(productId: number, entity_type): Observable<any> {
    if (entity_type === "contract") return this.http.get<any>(API.contracts + `/${productId}`);
    else return this.http.get<any>(API.offers + `/${productId}`);
  }

  indexProduct(params: IRequestPagination): Observable<any> {
    return this.http.get<IResponsePagination<OfferModel>>(API.offers, {
      params: { ...params },
    });
  }

  updateProduct(product, productId): Observable<any> {
    return this.http.post<any>(`${API.offers}/${productId}`, product);
  }

  deleteProduct(productId: number): Observable<any> {
    return this.http.delete<any>(`${API.offers}/${productId}`);
  }

  getLookups(category_code): Observable<any> {
    return this.http.get<any>(`${API.lookups}?category_code=${category_code}`);
  }

  getRoles(): Observable<any> {
    return this.http.get<any>(`${API.roles}/getAll/list`);
  }

  getCreditApprovalData(offerId: number): Observable<any> {
    return this.http.get<any>(`${API.offers}/tasks/${offerId}/credit-approval-data`);
  }

  createMonthioCaseFile(customers, offerId: number): Observable<any> {
    return this.http.post<any>(`${API.offers}/tasks/${offerId}/create-monthio-casefile`, customers);
  }

  creditApproval(offerId, status) {
    return this.http.post<any>(`${API.offers}/tasks/${offerId}/credit-approval`, status);
  }

  deleteMonthioCaseFile(offerId: number): Observable<any> {
    return this.http.delete<any>(`${API.offers}/tasks/${offerId}/delete-monthio-casefile`);
  }

  getVehiclesList(q): Observable<any> {
    return this.http.get<any>(`${API.vehicles}/getAll/list`, {
      params: {
        q,
      },
    });
  }

  getVehicleMakeList(q) {
    return this.http.get<any>(`${API.vehiclesMakes}/getAll/list`, {
      params: {
        q,
        order_column: "name",
        order_dir: "asc",
      },
    });
  }

  getVehicleMakeDetails(make_id) {
    return this.http.get<any>(`${API.vehiclesMakes}/${make_id}`);
  }

  getVehicleDetails(vehicle_id) {
    return this.http.get<any>(`${API.vehicles}/${vehicle_id}`);
  }

  getCustomerDetails(customer_id) {
    return this.http.get<any>(`${API.customers}/${customer_id}`);
  }

  chooseAssetEntity(entity_id, payload, entity_type) {
    return this.http.post<any>(`${API.entities}/${entity_type}/${entity_id}/choose-asset`, payload);
  }

  chooseCustomersEntity(payload, entity_type) {
    return this.http.post<any>(`${API.entities}/${entity_type}/choose-customer`, payload);
  }

  createEntity(entity_id, entity_type, payload) {
    return this.http.post<any>(`${API.entities}/${entity_type}/${entity_id}/create`, payload);
  }

  createContractFromOffer(offer_id) {
    return this.http.post<any>(`${API.offers}/${offer_id}/create-contract`, null);
  }

  createOfferFromOffer(offer_id) {
    return this.http.post<any>(`${API.offers}/${offer_id}/create-new-offer`, null);
  }

  requestContractFromOffer(offer_id, payload) {
    return this.http.post<any>(`${API.offers}/${offer_id}/request-contract`, payload);
  }

  downloadOffer(offer_id) {
    return this.http.post(`${API.offers}/${offer_id}/download`, null, {
      responseType: "blob",
      observe: "response",
    });
  }

  getOffertId(contract_number): Observable<any> {
    return this.http.get<any>(API.offers + `/${contract_number}`);
  }

  getOfferByNumber(number: number): Observable<any> {
    return this.http.get<any>(API.offers + "/number" + `/${number}`);
  }

  createInsurance(offerId, payload) {
    return this.http.post<any>(`${API.offers}/tasks/${offerId}/create-insurance`, payload);
  }
  riskAssessment(offerId, payload) {
    return this.http.put<any>(`${API.offers}/tasks/${offerId}/risk-class`, payload);
  }

  getRiskAssessmentDocuments(offerId) {
    return this.http.get(`${API.offers}/tasks/${offerId}/risk-assessment-docs`);
  }

  insertOfferCustomFields(offerId: number, payload: any): Observable<any> {
    return this.http.post<any>(`${API.offers}/${offerId}/custom-fields`, payload);
  }

  getOfferCustomFields(offerId: number): Observable<any> {
    return this.http.get<any>(`${API.offers}/${offerId}/custom-fields`);
  }

  createCreditroCase(offerId: number, payload: any): Observable<IResponse<ICreditroCase>> {
    return this.http.post<IResponse<ICreditroCase>>(`${API.offers}/tasks/${offerId}/create-creditro-case`, payload);
  }

  getCreditroCase(offerId: number): Observable<IResponse<IGetCreditroCase>> {
    return this.http.get<IResponse<IGetCreditroCase>>(`${API.offers}/tasks/${offerId}/get-creditro-case`);
  }
}
