<div class="kt-portlet datatable-view">
  <div
    class="kt-portlet__head kt-portlet__head__custom"
    *ngIf="contract_id"
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
  >
    <div class="kt-portlet__head-label">
      <h3 class="kt-portlet__head-title">
        {{ "PAYMENTS.VIEW.TITLE" | translate }}
      </h3>
      <div class="datatable-selected-filters-wrapper">
        <div *ngIf="selectedFilters['isBooked'] != ''" class="datatable-selected-filter-wrapper">
          <button class="datatable-selected-filters" mat-raised-button color="primary">
            {{ selectedFilters["isBooked"] }}
          </button>
          <button
            (click)="filters.is_booked = 'all'; selectedFilters['isBooked'] = ''; loadProductsList()"
            class="datatable-selected-filters-remove"
            mat-icon-button
            color="primary"
            matTooltip="{{ 'GENERAL.REMOVE' | translate }}"
          >
            <i class="fas fa-times-circle"></i>
          </button>
        </div>
        <div *ngIf="selectedFilters['from_date'] && !contract_id" class="datatable-selected-filter-wrapper">
          <button class="datatable-selected-filters" mat-raised-button color="primary">
            {{ selectedFilters["from_date"] }}
          </button>
          <button
            (click)="filters.from_date = ''; selectedFilters['from_date'] = ''; loadProductsList()"
            class="datatable-selected-filters-remove"
            mat-icon-button
            color="primary"
            matTooltip="{{ 'GENERAL.REMOVE' | translate }}"
          >
            <i class="fas fa-times-circle"></i>
          </button>
        </div>
        <div *ngIf="selectedFilters['to_date'] && !contract_id" class="datatable-selected-filter-wrapper">
          <button class="datatable-selected-filters" mat-raised-button color="primary">
            {{ selectedFilters["to_date"] }}
          </button>
          <button
            (click)="filters.to_date = ''; selectedFilters['to_date'] = ''; loadProductsList()"
            class="datatable-selected-filters-remove"
            mat-icon-button
            color="primary"
            matTooltip="{{ 'GENERAL.REMOVE' | translate }}"
          >
            <i class="fas fa-times-circle"></i>
          </button>
        </div>
        <div *ngIf="selectedFilters['customerName'] && !contract_id" class="datatable-selected-filter-wrapper">
          <button class="datatable-selected-filters" mat-raised-button color="primary">
            {{ selectedFilters["customerName"] }}
          </button>
          <button
            (click)="filters.customer = ''; selectedFilters['customerName'] = ''; loadProductsList()"
            class="datatable-selected-filters-remove"
            mat-icon-button
            color="primary"
            matTooltip="{{ 'GENERAL.REMOVE' | translate }}"
          >
            <i class="fas fa-times-circle"></i>
          </button>
        </div>
        <div *ngIf="selectedFilters['contract'] && !contract_id" class="datatable-selected-filter-wrapper">
          <button class="datatable-selected-filters" mat-raised-button color="primary">
            Kontrakt #{{ selectedFilters["contract"] }}
          </button>
          <button
            (click)="filters.contract = ''; selectedFilters['contract'] = ''; loadProductsList()"
            class="datatable-selected-filters-remove"
            mat-icon-button
            color="primary"
            matTooltip="{{ 'GENERAL.REMOVE' | translate }}"
          >
            <i class="fas fa-times-circle"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="kt-portlet__head-toolbar">
      <div class="datatable-search">
        <div class="search-wrapper" style="position: relative">
          <span class="kt-svg-icon search-icon" [inlineSVG]="'./assets/media/icons/magnify.svg'"></span>
          <input
            placeholder="{{ 'GENERAL.SEARCH' | translate }}"
            class="form-control"
            #searchInput
            style="height: calc(1.5em + 1rem + 2px)"
          />
          <button
            #searchButton
            mat-raised-button
            style="
              position: absolute;
              right: 0;
              top: 0;
              bottom: 0;
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
            "
            color="primary"
          >
            Søg
          </button>
        </div>
      </div>
      <datatable-filter-dropdown (onReset)="resetFilters()" [filters]="filters">
        <mat-accordion>
          <mat-expansion-panel class="for-filters">
            <mat-expansion-panel-header></mat-expansion-panel-header>
            <div class="kt-form__control">
              <mat-radio-group [(ngModel)]="filters.is_booked" (change)="loadProductsList()">
                <mat-radio-button value="all" [checked]="filters['is_booked'] == 'all'">{{
                  "GENERAL.ALL" | translate
                }}</mat-radio-button>
                <mat-radio-button value="booked">{{ "GENERAL.YES" | translate }} </mat-radio-button>
                <mat-radio-button value="not_booked"> {{ "GENERAL.NO" | translate }}</mat-radio-button>
              </mat-radio-group>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
        <mat-accordion *ngIf="!contract_id">
          <mat-expansion-panel class="for-filters">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ "PAYMENTS.VIEW.TABLE_COLUMNS.TYPE" | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="kt-form__control">
              <mat-radio-group [(ngModel)]="filters.type" (change)="loadProductsList()">
                <mat-radio-button value="contract" [checked]="filters['type'] == 'contract'">{{
                  "PAYMENTS.VIEW.TABLE_COLUMNS.VEHICLE" | translate
                }}</mat-radio-button>
                <mat-radio-button value="vehicle">
                  {{ "PAYMENTS.VIEW.TABLE_COLUMNS.CONTRACT" | translate }}</mat-radio-button
                >
              </mat-radio-group>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
        <mat-accordion *ngIf="!contract_id">
          <mat-expansion-panel class="for-filters">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ "PAYMENTS.VIEW.TABLE_COLUMNS.DATE_FROM" | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="kt-form__control">
              <mat-form-field class="mat-form-field-fluid">
                <input
                  matInput
                  [(ngModel)]="filters.from_date"
                  (ngModelChange)="loadProductsList()"
                  [matDatepicker]="dateFromPicker"
                  placeholder="{{ 'PAYMENTS.VIEW.TABLE_COLUMNS.DATE_FROM' | translate }}"
                  type="text"
                />
                <mat-datepicker-toggle matSuffix [for]="dateFromPicker"> </mat-datepicker-toggle>
                <mat-datepicker #dateFromPicker></mat-datepicker>
              </mat-form-field>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
        <mat-accordion *ngIf="!contract_id">
          <mat-expansion-panel class="for-filters">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ "PAYMENTS.VIEW.TABLE_COLUMNS.DATE_TO" | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="kt-form__control">
              <mat-form-field class="mat-form-field-fluid">
                <input
                  matInput
                  [(ngModel)]="filters.to_date"
                  (ngModelChange)="loadProductsList()"
                  [matDatepicker]="dateToPicker"
                  placeholder="{{ 'PAYMENTS.VIEW.TABLE_COLUMNS.DATE_TO' | translate }}"
                  type="text"
                />
                <mat-datepicker-toggle matSuffix [for]="dateToPicker"> </mat-datepicker-toggle>
                <mat-datepicker #dateToPicker></mat-datepicker>
              </mat-form-field>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
        <mat-accordion *ngIf="!contract_id">
          <mat-expansion-panel class="for-filters">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ "OFFERS.VIEW.TABLE_COLUMNS.CUSTOMER" | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="kt-form__control position-relative">
              <mat-form-field class="example-full-width searchable">
                <span class="kt-svg-icon search-icon" [inlineSVG]="'./assets/media/icons/magnify.svg'"></span>
                <input
                  type="text"
                  (input)="filterCustomers($event)"
                  placeholder="{{ 'OFFERS.VIEW.TABLE_COLUMNS.CUSTOMER' | translate }}"
                  aria-label="Number"
                  [(ngModel)]="filters.customer"
                  matInput
                  [matAutocomplete]="auto"
                />
                <mat-autocomplete
                  #auto="matAutocomplete"
                  [displayWith]="customerDisplayFn"
                  (optionSelected)="customerSelected($event)"
                >
                  <mat-option *ngFor="let customer of filteredCustomers | async" [value]="customer">
                    {{ customer.name }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <span class="no-filter-data mat-error" *ngIf="noCustomersFound">{{
                "GENERAL.NO_RESULTS_FOUND" | translate
              }}</span>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
        <mat-accordion *ngIf="!contract_id">
          <mat-expansion-panel class="for-filters">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ "PAYMENTS.VIEW.TABLE_COLUMNS.CONTRACT" | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="kt-form__control position-relative">
              <mat-form-field class="example-full-width searchable">
                <span class="kt-svg-icon search-icon" [inlineSVG]="'./assets/media/icons/magnify.svg'"></span>
                <input
                  type="text"
                  (input)="filterContracts($event)"
                  placeholder="{{ 'PAYMENTS.VIEW.TABLE_COLUMNS.CONTRACT' | translate }}"
                  aria-label="Number"
                  [(ngModel)]="filters.contract"
                  matInput
                  [matAutocomplete]="auto1"
                />
                <mat-autocomplete
                  #auto1="matAutocomplete"
                  [displayWith]="contractDisplayFn"
                  (optionSelected)="contractSelected($event)"
                >
                  <mat-option *ngFor="let contract of filteredContracts | async" [value]="contract">
                    {{ "PAYMENTS.VIEW.TABLE_COLUMNS.CONTRACT" | translate }} #{{ contract.number }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <span class="no-filter-data mat-error" *ngIf="noContractsFound">{{
                "GENERAL.NO_RESULTS_FOUND" | translate
              }}</span>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </datatable-filter-dropdown>
      <div class="btn-container">
        <button
          (click)="addBulkInvoices()"
          [hidden]="dialogData.contract_id"
          [disabled]="isCancelled"
          mat-raised-button
          color="primary"
          matTooltip="{{ 'PAYMENTS.VIEW.ADD_BULK_INVOICES' | translate }}"
        >
          {{ "PAYMENTS.VIEW.ADD_BULK_INVOICES" | translate }}
        </button>
        <button
          *ngxPermissionsOnly="['create_contract_payment']"
          [hidden]="!dialogData.contract_id || from == 'vehicle'"
          (click)="createPayment()"
          [disabled]="isCancelled"
          class="btn btn-primary"
          color="primary"
          mat-raised-button
        >
          {{ "CONTRACTS.DETAILS.LATEST_PAYMENTS.CREATE_PAYMENT" | translate }}
        </button>
        <button
          *ngxPermissionsOnly="['create_contract_payment']"
          [hidden]="!dialogData.contract_id || from == 'vehicle'"
          (click)="prepareInvoice()"
          [disabled]="isCancelled"
          class="btn btn-primary"
          color="primary"
          mat-raised-button
        >
          {{ "CONTRACTS.DETAILS.LATEST_PAYMENTS.CREATE_INVOICE" | translate }}
        </button>
        <button
          class="btn btn-primary"
          color="primary"
          mat-raised-button
          (click)="onBulkDeletePayments()"
          [disabled]="isBookedPaymentsSelected || !selection.selected.length || isCancelled"
        >
          {{ "PAYMENTS.VIEW.DELETE_BUTTON" | translate }}
        </button>
        <button
          class="btn btn-primary"
          color="primary"
          mat-raised-button
          (click)="onBulkReversePayments()"
          [disabled]="isUnbookedPaymentsSelected || !selection.selected.length || isCancelled"
        >
          {{ "PAYMENTS.VIEW.REVERSE_PAYMENTS_BUTTON" | translate }}
        </button>
      </div>
    </div>
  </div>

  <div class="kt-portlet__head" *ngIf="!contract_id">
    <div class="kt-portlet__head-label">
      <h3 class="kt-portlet__head-title">
        {{ "PAYMENTS.VIEW.TITLE" | translate }}
      </h3>
      <div class="datatable-selected-filters-wrapper">
        <div *ngIf="selectedFilters['isBooked'] != ''" class="datatable-selected-filter-wrapper">
          <button class="datatable-selected-filters" mat-raised-button color="primary">
            {{ selectedFilters["isBooked"] }}
          </button>
          <button
            (click)="filters.is_booked = 'all'; selectedFilters['isBooked'] = ''; loadProductsList()"
            class="datatable-selected-filters-remove"
            mat-icon-button
            color="primary"
            matTooltip="{{ 'GENERAL.REMOVE' | translate }}"
          >
            <i class="fas fa-times-circle"></i>
          </button>
        </div>
        <div *ngIf="filters.type" class="datatable-selected-filter-wrapper">
          <button class="datatable-selected-filters" mat-raised-button color="primary">
            {{
              (filters.type === "contract"
                ? "PAYMENTS.VIEW.TABLE_COLUMNS.CONTRACT"
                : "PAYMENTS.VIEW.TABLE_COLUMNS.VEHICLE"
              ) | translate
            }}
          </button>
          <button
            (click)="filters.type = 'contract'; loadProductsList()"
            class="datatable-selected-filters-remove"
            mat-icon-button
            color="primary"
            matTooltip="{{ 'GENERAL.REMOVE' | translate }}"
          >
            <i class="fas fa-times-circle"></i>
          </button>
        </div>
        <div *ngIf="selectedFilters['from_date'] && !contract_id" class="datatable-selected-filter-wrapper">
          <button class="datatable-selected-filters" mat-raised-button color="primary">
            {{ selectedFilters["from_date"] }}
          </button>
          <button
            (click)="filters.from_date = ''; selectedFilters['from_date'] = ''; loadProductsList()"
            class="datatable-selected-filters-remove"
            mat-icon-button
            color="primary"
            matTooltip="{{ 'GENERAL.REMOVE' | translate }}"
          >
            <i class="fas fa-times-circle"></i>
          </button>
        </div>
        <div *ngIf="selectedFilters['to_date'] && !contract_id" class="datatable-selected-filter-wrapper">
          <button class="datatable-selected-filters" mat-raised-button color="primary">
            {{ selectedFilters["to_date"] }}
          </button>
          <button
            (click)="filters.to_date = ''; selectedFilters['to_date'] = ''; loadProductsList()"
            class="datatable-selected-filters-remove"
            mat-icon-button
            color="primary"
            matTooltip="{{ 'GENERAL.REMOVE' | translate }}"
          >
            <i class="fas fa-times-circle"></i>
          </button>
        </div>
        <div *ngIf="selectedFilters['customerName'] && !contract_id" class="datatable-selected-filter-wrapper">
          <button class="datatable-selected-filters" mat-raised-button color="primary">
            {{ selectedFilters["customerName"] }}
          </button>
          <button
            (click)="filters.customer = ''; selectedFilters['customerName'] = ''; loadProductsList()"
            class="datatable-selected-filters-remove"
            mat-icon-button
            color="primary"
            matTooltip="{{ 'GENERAL.REMOVE' | translate }}"
          >
            <i class="fas fa-times-circle"></i>
          </button>
        </div>
        <div *ngIf="selectedFilters['contract'] && !contract_id" class="datatable-selected-filter-wrapper">
          <button class="datatable-selected-filters" mat-raised-button color="primary">
            Kontrakt #{{ selectedFilters["contract"] }}
          </button>
          <button
            (click)="filters.contract = ''; selectedFilters['contract'] = ''; loadProductsList()"
            class="datatable-selected-filters-remove"
            mat-icon-button
            color="primary"
            matTooltip="{{ 'GENERAL.REMOVE' | translate }}"
          >
            <i class="fas fa-times-circle"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="kt-portlet__head-toolbar">
      <div class="datatable-search">
        <div class="search-wrapper" style="position: relative">
          <span class="kt-svg-icon search-icon" [inlineSVG]="'./assets/media/icons/magnify.svg'"></span>
          <input
            placeholder="{{ 'GENERAL.SEARCH' | translate }}"
            class="form-control"
            #searchInput
            style="height: calc(1.5em + 1rem + 2px)"
          />
          <button
            #searchButton
            mat-raised-button
            style="
              position: absolute;
              right: 0;
              top: 0;
              bottom: 0;
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
            "
            color="primary"
          >
            Søg
          </button>
        </div>
      </div>
      <datatable-filter-dropdown (onReset)="resetFilters()" [filters]="filters">
        <mat-accordion>
          <mat-expansion-panel class="for-filters">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ "PAYMENTS.VIEW.TABLE_COLUMNS.BOOKED_STATUS" | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="kt-form__control">
              <mat-radio-group [(ngModel)]="filters.is_booked" (change)="loadProductsList()">
                <mat-radio-button value="all" [checked]="filters['is_booked'] == 'all'">{{
                  "GENERAL.ALL" | translate
                }}</mat-radio-button>
                <mat-radio-button value="booked">{{ "GENERAL.YES" | translate }} </mat-radio-button>
                <mat-radio-button value="not_booked"> {{ "GENERAL.NO" | translate }}</mat-radio-button>
              </mat-radio-group>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
        <mat-accordion *ngIf="!contract_id">
          <mat-expansion-panel class="for-filters">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ "PAYMENTS.VIEW.TABLE_COLUMNS.TYPE" | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="kt-form__control">
              <mat-radio-group [(ngModel)]="filters.type" (change)="loadProductsList()">
                <mat-radio-button value="contract" [checked]="filters['type'] == 'contract'">{{
                  "PAYMENTS.VIEW.TABLE_COLUMNS.CONTRACT" | translate
                }}</mat-radio-button>
                <mat-radio-button [checked]="filters['type'] == 'vehicle'" value="vehicle">
                  {{ "PAYMENTS.VIEW.TABLE_COLUMNS.VEHICLE" | translate }}</mat-radio-button
                >
              </mat-radio-group>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
        <mat-accordion *ngIf="!contract_id">
          <mat-expansion-panel class="for-filters">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ "PAYMENTS.VIEW.TABLE_COLUMNS.DATE_FROM" | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="kt-form__control">
              <mat-form-field class="mat-form-field-fluid">
                <input
                  matInput
                  [(ngModel)]="filters.from_date"
                  (ngModelChange)="loadProductsList()"
                  [matDatepicker]="dateFromPicker"
                  placeholder="{{ 'PAYMENTS.VIEW.TABLE_COLUMNS.DATE_FROM' | translate }}"
                  type="text"
                />
                <mat-datepicker-toggle matSuffix [for]="dateFromPicker"> </mat-datepicker-toggle>
                <mat-datepicker #dateFromPicker></mat-datepicker>
              </mat-form-field>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
        <mat-accordion *ngIf="!contract_id">
          <mat-expansion-panel class="for-filters">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ "PAYMENTS.VIEW.TABLE_COLUMNS.DATE_TO" | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="kt-form__control">
              <mat-form-field class="mat-form-field-fluid">
                <input
                  matInput
                  [(ngModel)]="filters.to_date"
                  (ngModelChange)="loadProductsList()"
                  [matDatepicker]="dateToPicker"
                  placeholder="{{ 'PAYMENTS.VIEW.TABLE_COLUMNS.DATE_TO' | translate }}"
                  type="text"
                />
                <mat-datepicker-toggle matSuffix [for]="dateToPicker"> </mat-datepicker-toggle>
                <mat-datepicker #dateToPicker></mat-datepicker>
              </mat-form-field>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
        <mat-accordion *ngIf="!contract_id">
          <mat-expansion-panel class="for-filters">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ "OFFERS.VIEW.TABLE_COLUMNS.CUSTOMER" | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="kt-form__control position-relative">
              <mat-form-field class="example-full-width searchable">
                <span class="kt-svg-icon search-icon" [inlineSVG]="'./assets/media/icons/magnify.svg'"></span>
                <input
                  type="text"
                  (input)="filterCustomers($event)"
                  placeholder="{{ 'OFFERS.VIEW.TABLE_COLUMNS.CUSTOMER' | translate }}"
                  aria-label="Number"
                  [(ngModel)]="filters.customer"
                  matInput
                  [matAutocomplete]="auto"
                />
                <mat-autocomplete
                  #auto="matAutocomplete"
                  [displayWith]="customerDisplayFn"
                  (optionSelected)="customerSelected($event)"
                >
                  <mat-option *ngFor="let customer of filteredCustomers | async" [value]="customer">
                    {{ customer.name }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <span class="no-filter-data mat-error" *ngIf="noCustomersFound">{{
                "GENERAL.NO_RESULTS_FOUND" | translate
              }}</span>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
        <mat-accordion *ngIf="!contract_id">
          <mat-expansion-panel class="for-filters">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ "PAYMENTS.VIEW.TABLE_COLUMNS.CONTRACT" | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="kt-form__control position-relative">
              <mat-form-field class="example-full-width searchable">
                <span class="kt-svg-icon search-icon" [inlineSVG]="'./assets/media/icons/magnify.svg'"></span>
                <input
                  type="text"
                  (input)="filterContracts($event)"
                  placeholder="{{ 'PAYMENTS.VIEW.TABLE_COLUMNS.CONTRACT' | translate }}"
                  aria-label="Number"
                  [(ngModel)]="filters.contract"
                  matInput
                  [matAutocomplete]="auto1"
                />
                <mat-autocomplete
                  #auto1="matAutocomplete"
                  [displayWith]="contractDisplayFn"
                  (optionSelected)="contractSelected($event)"
                >
                  <mat-option *ngFor="let contract of filteredContracts | async" [value]="contract">
                    {{ "PAYMENTS.VIEW.TABLE_COLUMNS.CONTRACT" | translate }} #{{ contract.number }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <span class="no-filter-data mat-error" *ngIf="noContractsFound">{{
                "GENERAL.NO_RESULTS_FOUND" | translate
              }}</span>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </datatable-filter-dropdown>
      <div class="btn-container">
        <button
          (click)="addBulkInvoices()"
          *ngIf="!dialogData.vehicle_id"
          [disabled]="isCancelled"
          mat-raised-button
          color="primary"
          matTooltip="{{ 'PAYMENTS.VIEW.ADD_BULK_INVOICES' | translate }}"
        >
          {{ "PAYMENTS.VIEW.ADD_BULK_INVOICES" | translate }}
        </button>
        <button
          *ngIf="!dialogData.contract_id || from == 'vehicle'"
          (click)="onPrepareVehicleInvoice.emit()"
          class="btn btn-primary"
          color="primary"
          mat-raised-button
        >
          {{ "CONTRACTS.DETAILS.LATEST_PAYMENTS.CREATE_INVOICE" | translate }}
        </button>
        <button
          class="btn btn-primary"
          color="primary"
          mat-raised-button
          (click)="onBulkDeletePayments()"
          [disabled]="isBookedPaymentsSelected || !selection.selected.length || isCancelled"
        >
          {{ "PAYMENTS.VIEW.DELETE_BUTTON" | translate }}
        </button>
        <button
          class="btn btn-primary"
          color="primary"
          mat-raised-button
          (click)="onBulkReversePayments()"
          [disabled]="isUnbookedPaymentsSelected || !selection.selected.length || isCancelled"
        >
          {{ "PAYMENTS.VIEW.REVERSE_PAYMENTS_BUTTON" | translate }}
        </button>
      </div>
    </div>
  </div>

  <div class="kt-portlet__body">
    <div class="mat-table__wrapper datatable-container">
      <div class="datatable-table-container">
        <table
          #table
          mat-table
          [dataSource]="data"
          class="example-table mat-elevation-z8"
          matSort
          matSortDisableClear
          multiTemplateDataRows
        >
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef width="5%">
              <mat-checkbox
                (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"
              >
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row" width="5%">
              <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="selectPayment($event, row)"
                [checked]="selection.isSelected(row)"
              >
              </mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="expand">
            <th mat-header-cell *matHeaderCellDef class="expand-entity-header"></th>
            <td
              mat-cell
              *matCellDef="let row"
              class="expand-entity-info"
              [class.entity-expanded-row]="expandedElement === row"
            >
              <button
                class="expand-info"
                mat-icon-button
                color="primary"
                (click)="expandedElement = expandedElement === row ? null : row"
              >
                <i class="far fa-plus-square"></i>
              </button>
              <button
                mat-icon-button
                class="collapse-info"
                color="warn"
                (click)="expandedElement = expandedElement === row ? null : row"
              >
                <i class="far fa-minus-square"></i>
              </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header width="10%">
              {{ "PAYMENTS.VIEW.TABLE_COLUMNS.DATE" | translate }}
            </th>
            <td mat-cell *matCellDef="let row" width="10%">{{ row.date | dmyDate }}</td>
          </ng-container>

          <ng-container matColumnDef="customer">
            <th mat-header-cell *matHeaderCellDef class="responsive" width="10%">
              {{ "PAYMENTS.VIEW.TABLE_COLUMNS.CUSTOMER_NAME" | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let row"
              [matTooltip]="row.customer?.name"
              class="responsive curser-initial"
              width="10%"
            >
              {{ this.layoutUtilsService.sliceWord(row.customer?.name) }}
            </td>
          </ng-container>

          <ng-container matColumnDef="product_line_description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="responsive" width="10%">
              {{ "PAYMENTS.VIEW.TABLE_COLUMNS.TEXT" | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let row"
              class="responsive"
              width="10%"
              [matTooltip]="row?.product_line_description"
            >
              {{ row.product_line_description }}
            </td>
          </ng-container>

          <ng-container matColumnDef="price_excl_vat_cents">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="responsive" width="10%">
              {{ "PAYMENTS.VIEW.TABLE_COLUMNS.AMOUNT" | translate }}
            </th>
            <td mat-cell *matCellDef="let row" class="responsive" width="10%">
              {{ row.price_excl_vat_cents | number : "1.2-2" }}
            </td>
          </ng-container>

          <ng-container matColumnDef="vat_cents">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="responsive" width="10%">
              {{ "PAYMENTS.VIEW.TABLE_COLUMNS.VAT_CENTS" | translate }}
            </th>
            <td mat-cell *matCellDef="let row" class="responsive" width="10%">
              {{ row.vat_cents | number : "1.2-2" }}
            </td>
          </ng-container>

          <ng-container matColumnDef="total_amount">
            <th mat-header-cell *matHeaderCellDef class="responsive" width="10%">
              {{ "PAYMENTS.VIEW.TABLE_COLUMNS.TOTAL_AMOUNT" | translate }}
            </th>
            <td mat-cell *matCellDef="let row" class="responsive" width="10%">
              {{ row.total_amount | number : "1.2-2" }}
            </td>
          </ng-container>

          <ng-container matColumnDef="vat_deduction_corporate_cents">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="responsive" width="10%">
              {{ "PAYMENTS.VIEW.TABLE_COLUMNS.VAT_DEDUCTION" | translate }}
            </th>
            <td mat-cell *matCellDef="let row" class="responsive" width="10%">
              {{ row.vat_deduction_corporate_cents | number : "1.2-2" }}
            </td>
          </ng-container>

          <ng-container matColumnDef="is_booked">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="responsive" width="5%">
              {{ "PAYMENTS.VIEW.TABLE_COLUMNS.BOOKED_STATUS" | translate }}
            </th>
            <td mat-cell *matCellDef="let row" class="responsive" width="5%">
              <span class="badge kt-bg-fill-default" *ngIf="!row.is_booked">
                {{ "GENERAL.NO" | translate }}
              </span>
              <span class="badge kt-bg-fill-success" *ngIf="row.is_booked">
                {{ "GENERAL.YES" | translate }}
              </span>
            </td>
          </ng-container>

          <!--
					<ng-container matColumnDef="updated_at">
						<th mat-header-cell *matHeaderCellDef mat-sort-header class="responsive" width="20%">{{'PAYMENTS.VIEW.TABLE_COLUMNS.UPDATED' | translate}}</th>
						<td mat-cell *matCellDef="let row" class="responsive" width="20%">
							<span [innerHTML]="row.updated_at | dmyDate | safe"></span>
						</td>
					</ng-container> -->

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef width="5%"></th>
            <td mat-cell *matCellDef="let row" width="5%">
              <kt-actions-menu>
                <!-- <li class="kt-nav__item">
									<a href="javascript:;" class="kt-nav__link">
										<span [inlineSVG]="'./assets/media/icons/edit.svg'"></span>
										<span class="kt-nav__link-text">
											{{'GENERAL.EDIT' | translate}}
										</span>
									</a>
								</li> -->
                <li *ngIf="row.is_booked" class="kt-nav__item" (click)="reverseProduct(row.id)">
                  <a href="javascript:;" class="kt-nav__link">
                    <span>
                      <i class="fas fa-redo-alt"></i>
                    </span>
                    <span class="kt-nav__link-text">
                      {{ "GENERAL.REVERSE_PAYMENTS_BUTTON" | translate }}
                    </span>
                  </a>
                </li>
                <li *ngIf="!row.is_booked" class="kt-nav__item" (click)="deleteProduct(row.id)">
                  <a href="javascript:;" class="kt-nav__link">
                    <span [inlineSVG]="'./assets/media/icons/trash.svg'"></span>
                    <span class="kt-nav__link-text">
                      {{ "GENERAL.DELETE" | translate }}
                    </span>
                  </a>
                </li>
              </kt-actions-menu>
            </td>
          </ng-container>

          <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
              <div
                class="entity-details-info-column"
                [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'"
              >
                <div class="entity-details-info-column-data">
                  <div class="entity-details-info-column-data-key">
                    {{ "PAYMENTS.VIEW.TABLE_COLUMNS.TEXT" | translate }}
                  </div>
                  <div class="entity-details-info-column-data-value">
                    {{ element.product_line_description }}
                  </div>
                </div>
                <div class="entity-details-info-column-data">
                  <div class="entity-details-info-column-data-key">
                    {{ "PAYMENTS.VIEW.TABLE_COLUMNS.BOOKED_STATUS" | translate }}
                  </div>
                  <div class="entity-details-info-column-data-value">
                    <span class="badge kt-bg-fill-default" *ngIf="!element.is_booked">
                      {{ "GENERAL.NO" | translate }}
                    </span>
                    <span class="badge kt-bg-fill-success" *ngIf="element.is_booked">
                      {{ "GENERAL.YES" | translate }}
                    </span>
                  </div>
                </div>
                <div class="entity-details-info-column-data">
                  <div class="entity-details-info-column-data-key">
                    {{ "PAYMENTS.VIEW.TABLE_COLUMNS.AMOUNT" | translate }}
                  </div>
                  <div class="entity-details-info-column-data-value">
                    {{ element.price_excl_vat_cents | number : "1.2-2" }}
                  </div>
                </div>
                <!-- <div class="entity-details-info-column-data">
									<div class="entity-details-info-column-data-key">
										{{'PAYMENTS.VIEW.TABLE_COLUMNS.CREATED' | translate}}
									</div>
									<div class="entity-details-info-column-data-value">
										<span [innerHTML]="element.created_at | dmyDate | safe"></span>
									</div>
								</div>
								<div class="entity-details-info-column-data">
									<div class="entity-details-info-column-data-key">
										{{'PAYMENTS.VIEW.TABLE_COLUMNS.UPDATED' | translate}}
									</div>
									<div class="entity-details-info-column-data-value">
										<span [innerHTML]="element.updated_at | dmyDate | safe"></span>
									</div>
								</div> -->
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns" class="entity-default-row"></tr>
          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="entity-details-info-row"></tr>
        </table>
        <div *ngIf="data.length == 0 && !isLoadingResults" class="d-flex justify-content-center">
          <p class="m-3">{{ "GENERAL.NO_RESULTS_FOUND" | translate }}</p>
        </div>
        <div class="mat-table__message" *ngIf="isRateLimitReached">
          {{ "GENERAL.SOMETHING_WENT_WRONG" | translate }}
        </div>
        <!-- <div class="mat-table__message" *ngIf="isLoadingResults">{{'GENERAL.PLEASE_WAIT' | translate}}</div> -->
      </div>

      <!-- start: BOTTOM -->
      <div class="mat-table__bottom">
        <mat-spinner [diameter]="20" *ngIf="isLoadingResults"></mat-spinner>
        <mat-paginator appStylePaginator [length]="resultsLength" showFirstLastButtons></mat-paginator>
      </div>
      <!-- end: BOTTOM -->
    </div>
  </div>
  <!-- end::Body -->
</div>
