export class VehicleModel {
  id: number;
  make: any;
  make_id: any;
  model_id: any;
  vehicle_make: any;
  model: any;
  vehicle_model: any;
  trim: string;
  first_registration_date: any;
  year: any;
  vin_number: any;
  vehicle_type_id: number;
  vehicle_type: any;
  is_used_vat: any;
  full_reg_tax: any;
  fuel_type: number;
  vehicle_condition_id: number;
  vehicle_condition: number;
  new_price_cents: number;
  fuel_consumption: number;
  co2_per_km: number;
  curb_weight: number;
  fuel_norm: string;
  battery_capacity: number;
  electric_consumption: number;
  electric_range: number;
  mileage_car: any;
  mileagePhoto?: File;
  market_car: any;
  vehicle_valuation: any;
  created_at: string;
  updated_at: string;
  next_inspection: string;
  horse_power?: number;

  //extra_equipment_value:any[];
  // num_airbags:number;
  // num_integrated_child_seats:number;
  // num_seat_belt_alarms:number;
  // is_special_usage:any;
  // is_ncap:any;
  // is_abs:any;

  clear() {
    this.make_id = "";
    this.model = "";
    this.trim = "";
    this.year = "";
    this.created_at = "";
    this.updated_at = null;
    this.vin_number = "";
    this.is_used_vat = false;

    //this.extra_equipment_value = [];
    // this.is_special_usage = false;
    // this.is_ncap = false;
    // this.is_abs = false;
    // this.mileage_car = [];
    // this.market_car = [];
  }
}
