import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { MatDatepicker } from "@angular/material/datepicker";
import moment, { Moment } from "moment";
import { Observable, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { LookupCategories } from "../../enums/lookups/lookupCategories";
import { FuelTypes } from "../../enums/vehicle/fuelTypes";
import { SharedService } from "../../shared.service";
import { BehaviorSubject } from "rxjs";
import { VehicleFieldEnum } from "../../../pages/settings/components/required-fields-settings-dialog/required-fields-enum";
import { Store } from "@ngrx/store";
import { AppState } from "../../../../../app/core/reducers";
import { IResponse } from "../../../../../app/core/_base/crud/interfaces/response-interface";
import { BaseSettingsModel } from "../../../../../app/views/pages/settings/base-settings.model";
import { IRequiredFieldsSettings } from "../../../../../app/views/pages/settings/components/required-fields-settings-dialog/required-fields-settings.interface";
import { selectSettings } from "../../../../../app/core/auth/_selectors/setting.selectors";
import { useVehicleFieldsSettings } from "../../../../../app/core/_base/crud/utils/form-utils";
import { EntityType } from "../../enums/entity/entityType";
import { DropzoneComponent } from "ngx-dropzone-wrapper";

@Component({
  selector: "kt-vehicle-step",
  templateUrl: "./vehicle-step.component.html",
  styleUrls: ["./vehicle-step.component.scss"],
})
export class VehicleStepComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() vehicleInfo!: any;
  @Output() vehicleErrorsSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @ViewChild("mileagePhoto") mileagePhotoComponent: DropzoneComponent;
  uploadMileagePhoto: File;
  dropZoneConfig = {
    acceptedFiles: ".png, .jpg, .jpeg, .gif, .pdf, .heic",
    maxFilesize: 20,
    maxFiles: 1,
  };
  fuelTypesFilter: Observable<any[]>;
  fuelNormsFilter: Observable<any[]>;
  vehicleConditionsFilter: Observable<any[]>;
  fuelTypesEnum = FuelTypes;
  vinNumberPattern: RegExp = /^(.{9}|.{17})$/;
  formSettings: BaseSettingsModel<IRequiredFieldsSettings>;
  isSettingsLoading: boolean = false;
  isMileagePhotoValid: boolean = true;
  isMileagePhotoRequired: boolean = false;
  settings$: Observable<IResponse<BaseSettingsModel<IRequiredFieldsSettings>>>;
  vehicleFieldEnum = VehicleFieldEnum;
  private componentSubscriptions: Subscription[] = [];
  vehicleForm = this._formBuilder.group({
    vehicle_type_id: [{ value: "", disabled: true }],
    make_id: [{ value: "", disabled: true }],
    model_id: [{ value: "", disabled: true }],
    vehicle_valuation: [{ value: false, disabled: true }, Validators.nullValidator],
    trim: ["", Validators.maxLength(100)],
    first_registration_date: [{ value: "", disabled: true }],
    year: [moment()],
    vin_number: ["", Validators.pattern(this.vinNumberPattern)],
    is_used_vat: [false],
    full_reg_tax: [false],
    fuel_type: [""],
    fuel_consumption: [""],
    new_price_cents: [""],
    vehicle_condition_id: [""],
    market_car: [""],
    mileage_car: [""],
    hasMileagePhoto: [false],
    electric_consumption: [""],
    electric_range: [""],
    fuel_norm: [""],
    co2_per_km: [""],
    curb_weight: [""],
    battery_capacity: [""],
    horse_power: [""],
    next_inspection: [""],
  });

  constructor(
    private _formBuilder: FormBuilder,
    private sharedService: SharedService,
    private cdr: ChangeDetectorRef,
    private store: Store<AppState>
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.initFilters();
    const settings$: Observable<IResponse<BaseSettingsModel<IRequiredFieldsSettings>>> =
      this.store.select(selectSettings);
    const settingSubscription = settings$.subscribe((settings) => {
      this.formSettings = settings.data;
      useVehicleFieldsSettings(settings, this.vehicleForm, EntityType.CONTRACT);
    });
    this.componentSubscriptions.push(settingSubscription);
  }

  ngAfterViewInit() {
    const vehicleControl = this.vehicleForm.controls;
    if (this.formSettings && !this.formSettings.is_active) {
      this.setVehicleValuationValidationForSettings(vehicleControl.vehicle_valuation.value);
      vehicleControl.vehicle_valuation.valueChanges.subscribe((value) => {
        this.setVehicleValuationValidationForSettings(value);
      });
    }

    this.setVehicleValuationValidation(vehicleControl.vehicle_valuation.value);
    vehicleControl.vehicle_valuation.valueChanges.subscribe((value) => {
      this.setVehicleValuationValidation(value);
    });
    vehicleControl.fuel_type.valueChanges.subscribe((value) => {
      this.setFuelTypeValidation(value);
    });

    if (this.formSettings && this.formSettings.value.vehicleFields.mileagePhoto.requiredInContractForm) {
      this.isMileagePhotoRequired = true;
    }

    this.isMileagePhotoValid = this.validateDropzone();
    this.vehicleErrorsSubject.next(this.vehicleForm.invalid || !this.isMileagePhotoValid);
    this.getVehicleFormError();
    this.cdr.detectChanges();
  }

  validateDropzone(): boolean {
    const dropzoneFiles = this.mileagePhotoComponent?.directiveRef?.dropzone()?.files;
    if (this.isMileagePhotoRequired && (!dropzoneFiles || dropzoneFiles.length === 0)) {
      return false;
    } else {
      this.uploadMileagePhoto = dropzoneFiles && dropzoneFiles.length >= 1 ? dropzoneFiles[0] : null;
      return true;
    }
  }

  setVehicleValuationValidation(vehicleValuation: boolean) {
    const vehicleControls = this.vehicleForm.controls;
    if (vehicleValuation) {
      vehicleControls.new_price_cents.setValidators([Validators.required]);
      vehicleControls.fuel_norm.setValidators([Validators.required]);
      vehicleControls.vehicle_condition_id.setValidators([Validators.required]);
      this.setFuelTypeValidation(vehicleControls.fuel_type.value);
    } else {
      vehicleControls.new_price_cents.setValidators([Validators.nullValidator]);
      vehicleControls.fuel_norm.setValidators([Validators.nullValidator]);
      vehicleControls.vehicle_condition_id.setValidators([Validators.nullValidator]);
      this.setFuelTypeValidation(vehicleControls.fuel_type.value);
    }
  }

  setVehicleValuationValidationForSettings(vehicleValuation: boolean) {
    const vehicleControls = this.vehicleForm.controls;
    if (vehicleValuation) {
      vehicleControls.first_registration_date.setValidators([Validators.required]);
    } else {
      vehicleControls.first_registration_date.setValidators([Validators.nullValidator]);
    }
    vehicleControls.first_registration_date.updateValueAndValidity();
  }

  initForm() {
    this.vehicleForm.patchValue(this.vehicleInfo);
    if (this.vehicleInfo.year) this.vehicleForm.controls.year.setValue(moment(this.vehicleInfo.year, "YYYY"));
    this.vehicleForm.controls.mileage_car.setValue(this.vehicleInfo.latest_mileage);
    this.vehicleForm.markAllAsTouched();
  }

  initFilters() {
    this.fuelTypesFilter = this.sharedService.getLookups(LookupCategories.FUEL_TYPES).pipe(map((res) => res.data));
    this.fuelNormsFilter = this.sharedService
      .getLookups(LookupCategories.FUEL_MEASUREMENT)
      .pipe(map((res) => res.data));
    this.vehicleConditionsFilter = this.sharedService.getVehicleConditions().pipe(map((res) => res.data));
  }

  getVehicleFormControl(control: string) {
    return this.vehicleForm.controls[control] as FormControl;
  }

  getVehicleFormError() {
    this.vehicleForm.valueChanges.subscribe((value) => {
      this.isMileagePhotoValid = this.validateDropzone();
      this.vehicleErrorsSubject.next(this.vehicleForm.invalid || !this.isMileagePhotoValid);
    });
  }

  displayFn(item: any): string {
    return item && item.name ? item.name : "";
  }

  setYear(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.vehicleForm.controls.year.value;
    ctrlValue.year(normalizedYear.year());
    this.vehicleForm.controls.year.setValue(ctrlValue);
    datepicker.close();
  }

  setFuelTypeValidation(fuelType: string) {
    const vehicleControl = this.vehicleForm.controls;
    if (fuelType === FuelTypes.ELECTRIC) {
      vehicleControl.fuel_consumption.setValidators([Validators.nullValidator]);
      vehicleControl.electric_consumption.setValidators([Validators.required]);
      vehicleControl.electric_range.setValidators([Validators.required]);
    } else {
      vehicleControl.fuel_consumption.setValidators([Validators.required]);
      vehicleControl.electric_consumption.setValidators([Validators.nullValidator]);
      vehicleControl.electric_range.setValidators([Validators.nullValidator]);
    }
  }

  onFileAdded(file: File) {
    this.uploadMileagePhoto = file;
    this.isMileagePhotoValid = this.validateDropzone();
  }

  onFileRemoved(event: any) {
    this.isMileagePhotoValid = this.validateDropzone();
  }

  public isControlRequired(formName: string) {
    return this.getVehicleFormControl(formName).hasValidator(Validators.required);
  }

  ngOnDestroy() {
    this.componentSubscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
